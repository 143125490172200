import React, { useState } from 'react'
import Pdf from '../../../../../../Components/Icons/Pdf'
import Filter from '../../../../../../Components/Icons/Filter'
import NewReportTable from '../../../../../../Components/Others/NewReportTable'
import CloseIcon from '../../../../../../Components/Icons/Close'
import { CancelButton, SubmitButton } from '../../../../../../Components/Utils/Buttons'
import { useFormik } from 'formik'
import UseFetchReportData from '../../../../../../Components/Hooks/useFetchReportData'
import axiosInstance from '../../../../../../Services/axiosCommon'

import FormikSelectTwo from '../../../../../../Components/Utils/Select'
import { NuDate } from '../../../../../../Components/Utils/Input'
import UseDropDown from '../../../../../../Components/Hooks/useDropDown'
import NuDateFormat from '../../../../../../Components/Utils/NuDate'

function CostSaving() {
    const [showFilter,setShowFilter] = useState(false)
    const { data: RFQAssignee } = UseDropDown("/user/procurement/drop-down");
    const [filter, setFilter] = useState(false);
    const headers = [
        {
            title: "Name",
            width: "40%",
            field: "name",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Orders",
            width: "15%",
            field: "count",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Supplier Quote Value",
            width: "15%",
            field: "value",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Negotiated Value",
            width: "15%",
            field: "ordered_price",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Cost Saving",
            width: "15%",
            field: "difference",
            textAlign: "left",
            rowAlign: "left",
          },
    ];

        const [filterProps, setFilterProps] = useState({
            name:"",
            dateFrom:"",
            dateTo:"",
        });

        const [params, setParams] = useState({
            name:"",
            dateFrom:"",
            dateTo:"",  
        });

       const formik = useFormik({
         enableReinitialize: true,
         initialValues: filterProps,
         validateOnChange: false,
         validateOnBlur: false,
         onSubmit: async (value) => {
           setParams({
            name:value.name,
            dateFrom:value.dateFrom,
            dateTo:value.dateTo,
           })
           setTimeout(() => {
            setFilter(true)
            closeFilterDialog();
           }, 1000);
         }
       }) 
       const {data,getData,dataLoading} = UseFetchReportData("/report/cost-save-by-procurement",params)


       function closeFilterDialog(){
         setShowFilter(false)
         formik.resetForm()
         setFilter(false)
       }





       const downloadPDF = async () => {
        try {
            const response = await axiosInstance.get(`/report/cost-save-by-procurement/pdf`,{params}, {
                responseType: 'blob' // Important to specify the response type as blob
            });
    
            // Create a Blob from the response
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    
            // Create a link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdfBlob);
            link.download = `Cost Saving Analysis Report ${NuDateFormat({value:new Date(),format:"DD-MM-YYYY"})} .pdf`; // The filename you want for the downloaded file
    
            // Append the link to the body (required for Firefox)
            document.body.appendChild(link);
    
            // Programmatically click the link to trigger the download
            link.click();
    
            // Clean up and remove the link
            link.parentNode.removeChild(link);
    
        } catch (error) {
            console.error('Error downloading the PDF:', error);
        }
    };

  return (
    <>
      <div className="w-full h-full">
        <div className="w-full h-9 flex justify-between items-center">
          <p className=" font-medium text-xs 2xl:text-sm">
            Cost Saving Analysis Report
          </p>
          <div className="flex gap-x-3">
            <button
              className="py-1 px-2 rounded flex justify-center items-center bg-[#b5f7f1]"
              onClick={() => setShowFilter(!showFilter)}
            >
              <Filter color="#048178" />
            </button>
            <button onClick={downloadPDF}>
              <Pdf />
            </button>
          </div>
        </div>
        <NewReportTable headers={headers} data={data} dataLoading={dataLoading} />
      </div>
      {showFilter && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <div className=" w-full flex justify-between">
              <h2 className="text-xl mb-4">Filter</h2>
              <div className=" w-6 h-6 rounded bg-red-400 flex justify-center items-center cursor-pointer" onClick={closeFilterDialog}>
                <CloseIcon color="white" width="16" height="16" />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
            <NuDate
                label="From Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="dateFrom"
                width="w-full"
              />
            <NuDate
                label="To Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="dateTo"
                width="w-full"
              />
              <FormikSelectTwo
                type="text"
                label="Sales Person"
                placeholder="Choose"
                Options={RFQAssignee}
                formik={formik}
                name="name"
                width="w-full"
                FixPosition={true}
                PortalTarget={false}
                isClear={true}
              />
              <div className=" w-full flex flex-wrap">
                <div className=" w-full flex justify-end gap-2">
                  <CancelButton handleClick={closeFilterDialog} />
                  <SubmitButton name="Apply" />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default CostSaving