import React, { useState } from 'react'
import Breadcrumb from '../../../Components/Utils/Breadcrumb'
import Select from "react-select";
import { Link } from 'react-router-dom';
import { TextMediumSM } from '../../../Components/Text/MediumText';

function ReportMainIndex() {
  const [selectedValues, setSelectedValues] = useState("")
  const [show,setShow] = useState("")

  function handleChange(e){
    setSelectedValues(e.value)
  }


  const styles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "0",
      color: "#000",
      textAlign: "left",
      // fontSize:'13px',
      fontWeight: "500",
      width: "100%",
      // zIndex:0
    }),
  };




  let ProcurementOptions = [
    {
      label: "Cost Saving Analysis",
      value:1,
      link:'/reports/procurement/cost-saving-analysis'
    },
  ]


  function handleSubmit(){
    if(selectedValues){
      setShow(selectedValues)
    }else{
      setShow("")
    }
  }

  return (
    <div className=' w-full h-full px-4 py-2  overflow-hidden'>
        <Breadcrumb
         items={[
            {
                name:"Report"
            }
         ]}
        />
        <div className=" w-[100%] h-[calc(100vh-130px)] mt-2 bg-white rounded-lg  overflow-y-auto p-3">
          <div className=' w-full h-full grid grid-cols-3 lg:grid-cols-4 gap-3 overflow-y-auto'>
            <div>
            <p className=' px-4 py-1 bg-baseColor-primary text-white font-medium text-sm 2xl:text-base mb-4'>Procurement Report</p>
            {
              ProcurementOptions && ProcurementOptions.map((item,index)=>{
                return (
                  <div key={index} className=' bg-slate-50 p-2 flex my-1 items-center rounded-lg hover:shadow-lg'>
                    <Link to={item.link}>
                      <TextMediumSM content={item.label||""} />
                    </Link>
                  </div>
                )
              })
            }
            </div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
    </div>
  )
}

export default ReportMainIndex