import React from "react";
import FileIcon from "../Icons/FileIcon";
import NuAmount from "../Utils/NuAmount";
import NuDate from "../Utils/NuDate";

function NewReportTable({ headers, dataLoading, data }) {
  function getField(row, field, typeValue) {
    if (field) {
      let splitValue = field.split(".");
      let value = "";
      if (splitValue.length >= 2) {
        if (splitValue.length == 3) {
          value = row[splitValue[0]][splitValue[1]][splitValue[2]];
        } else {
          value = row[splitValue[0]] ? row[splitValue[0]][splitValue[1]] : "";
        }
      } else {
        value = row[splitValue[0]];
      }

      if (typeValue === "date-time") {
        return (
          (value !== "" && value !== "None" && (
            <NuDate value={value} format="DD-MMM-YYYY hh:mm a" />
          )) ||
          ""
        );
      } else if (typeValue === "date") {
        return (
          (value !== "" && value !== "None" && (
            <NuDate value={value} format="DD-MMM-YYYY" />
          )) ||
          ""
        );
      } else if (typeValue === "time") {
        return (
          (value !== "" && value !== "None" && (
            <NuDate value={value} format="hh:mm a" />
          )) ||
          ""
        );
      } else if (typeValue === "amount") {
        return (
          (
            <NuAmount
              currency={row["vendorCurrency"] || row["currency"]}
              value={value}
            />
          ) || ""
        );
      } else if (Array.isArray(value)) {
        return value.join(", ");
      } else if (typeValue === "email") {
        return (
          <p className=" max-w-[130px] text-center overflow-x-hidden truncate">
            {value}
          </p>
        );
      } else {
        return value;
      }
    }
  }

  return (
    <div className=" w-full h-auto relative">
      <div className=" w-full sticky top-0">
        <table className="w-full">
          <thead>
            <tr className="text-xs font-semibold">
              {headers &&
                headers.map((header, i) => (
                  <th
                    className={`p-2 font-semibold truncate`}
                    style={{
                      width: header?.width,
                      textAlign: header?.rowAlign || "center",
                    }}
                    key={i}
                  >
                    {header.title || "-"}
                  </th>
                ))}
            </tr>
          </thead>
        </table>
        <div className="w-full h-[1px] opacity-30 bg-[#C4C2C2]  mx-auto"></div>
      </div>
      <div className={` h-auto z-10 pb-4 mb-16`}>
        <table className="w-full">
          <tbody>
            {dataLoading == true ? (
              <>
                <div className=" w-full h-[50vh] flex justify-center items-center">
                  <div className=" flex flex-col justify-center items-center">
                    <div className="flex flex-row gap-2">
                      <div className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce" />
                      <div className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce [animation-delay:-.3s]" />
                      <div className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce [animation-delay:-.5s]" />
                      <div className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce [animation-delay:-.7s]" />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {data?.length > 0 ? (
                  <>
                    {data.map((row, rowIndex) => (
                      <tr
                        className="odd:bg-white text-xs even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105"
                        key={rowIndex}
                      >
                        {headers.map((header, colIndex) => {
                          return (
                            <td
                              className={`py-3 px-2`}
                              key={colIndex}
                              style={{
                                width: header?.width,
                                textAlign: header?.textAlign || "center",
                              }}
                            >
                              {header.isCondition
                                ? row[header.field]
                                  ? "Yes"
                                  : "No"
                                : getField(row, header?.field, header?.type)}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <div className=" w-full h-[50vh] flex justify-center items-center">
                      <div className=" flex flex-col justify-center items-center">
                        <FileIcon />
                        <p className=" font-medium text-sm 2xl:text-base text-baseColor-secondary my-2">
                          No Data found
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default NewReportTable;
