import axios from "axios";
import DecryptData from "../Components/Utils/Decrypt";
import logout from "../Components/Utils/Logout";

const SERVER_URL = window.WEB_APP_API_URL;

const auth_key = DecryptData('nEkOtReSuEdArT')||"";

const getAuthKey = () => {
  const userToken = auth_key;
  if (!userToken) {
    // toast.warning('please login again')
  }
  return userToken || "";
};

let token = getAuthKey() || "";
const axiosInstance = axios.create({
  baseURL: SERVER_URL,
  headers: {
    token: token,
  },
});

axiosInstance.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      if (response.data?.message === "Token is invalid"||response.data?.message === "Token missing") {
        logout()
      } else {
        resolve(response);
      }
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 401) {
      logout()
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default axiosInstance;
