import React, { useEffect, useState } from 'react';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, CreateButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import axiosInstance from '../../../../Services/axiosCommon';
import Edit from '../../../../Components/Icons/Edit';
import Delete from '../../../../Components/Icons/Delete';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import ModalContainer from '../../../../Components/Others/ModalContainer';
import { useToast } from '../../../../Components/Others/ToastServices';
import NuDate from '../../../../Components/Utils/NuDate';
import NuAmount from '../../../../Components/Utils/NuAmount';

const ViewPayables = () => {
  // const { patientsViewId } = useParams();
  const location = useLocation();
  const Paydata = location.state.data;
  const pageIndex = location.state?.pageIndex;
  const searchQuery = location.state?.searchQuery;
  
    const { payablesViewId } = useParams();
    const [data, setData] = useState(Paydata?Paydata:null);
    const { toast } = useToast();
  async function getPayableView() {
    await axiosInstance
      .get(`/payable/${payablesViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  console.log('sss',data?.productList);
  useEffect(()=>{
    getPayableView();
  },[]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { handle } = useDeleteData(getPayableView);
  // const handleDelete = async () => handle(url + `${id}`,setIsModalOpen);
  const handleDelete = (id) =>{
    axiosInstance.delete(`/payable/delete-payment/${id}`)
    getPayableView()
  }
  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
       <Breadcrumb
         items={
          [
            { name: "Payment",
            link: "/payments/payables" },  
          {
            name: "Payable",
            link: "/payments/payables"
          },
          {
            name: "View Payables"
          }]
         }
        />
        <div className=" flex gap-2">
          <Link to={'/payments/payables'} state={{currentPage:pageIndex,searchQuery:searchQuery!=""?searchQuery:""}}><BackButton link={true}/></Link>
          <Link to={`/payments/payables/edit/${data?.payableId}`} state={{data: data, from:`/payments/payables/view/${data.payableId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-y-scroll mt-4">
            <div className='flex justify-between'>
              <h2 className='text-base font-normal text-[#111537] p-3'>Payable Details</h2>
              <div className='mt-3'><Link to={`/payments/payables/add/${data?.payableId}`}><CreateButton label='Paid Amount' /></Link></div>
            </div>
              <div className=" w-full flex flex-wrap ">
                <div className=" w-full grid grid-cols-3 gap-x-8 m-4">
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Payable ID</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.payableId || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Invoice Incoming</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.invoiceRef || '-'}</p>
                  </div>
                
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Pay To</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.payToName || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Account Type</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.accountType || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Amount</p>
                  <p className='w-1/2 py-2 text-xs  text-[#606279]'>: <NuAmount value= {data.amount}/>
    </p>
</div>
                 
<div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Paid Amount</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: <NuAmount value= {data.paidAmount}/></p>
                  </div>
                  <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Balance Amount</p>
                  <p className='w-1/2 py-2 text-xs  text-[#606279]'>: <NuAmount value= {data.balanceAmount}/>
  </p>
</div>
<div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Due Date</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.dueDate ? (
                  <NuDate value={data?.dueDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Days Elapsed</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.daysElapsed || '0'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Category</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.category || '-'}</p>
                  </div>
                 
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Description</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.description || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.status || '-'}</p>
                  </div>
                 
                 
                  </div>
                  <div className="w-full h-48 bg-white overflow-y-scroll mt-1">
            <div className="w-full py-[1%] bg-baseColor-primary flex justify-between px-3 rounded-md ">
              <p className=" w-[20%] text-left text-white text-xs font-medium">
                Paid Amount
              </p>
              <p className=" w-[20%] text-left text-white text-xs font-medium">
                Bal. Amount
              </p>
              <p className=" w-[20%] text-left text-white text-xs font-medium">
                Paid On
              </p>
              <p className=" w-[20%] text-left text-white text-xs font-medium">
                Paymt. Method
              </p>
              <p className=" w-[20%] text-left text-white text-xs font-medium">
                Comments
              </p>
              {/* <p className=" w-[10%] text-left text-white text-xs font-medium">
                Edit
              </p> */}
              <p className=" w-[5%] text-left text-white text-xs font-medium">
                Action
              </p>
            </div>
             {data?.paymentHistory?.map((List, index) => {
              return (
                <div key={index} className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100 px-3">
                 <p className="w-[20%] text-left text-black text-xs font-light py-1">
  {List?.paidAmount ? Number(List.paidAmount).toLocaleString(undefined, { 
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2 
    }) : '-'}
</p>
<p className="w-[20%] text-left text-black text-xs font-light py-1">
  {List?.balanceAmount ? Number(List.balanceAmount).toLocaleString(undefined, { 
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2 
    }) : '-'}
</p>

<p className="w-[20%] text-left text-black text-xs font-light py-1">
  {List?.paidOn ? (
    <NuDate value={List.paidOn} format="DD-MMM-YYYY HH:mm" />
  ) : (
    "N/A"
  )}
</p>

                  <p className=" w-[20%] text-left text-black text-xs font-light py-1">
                    {List?.paymentMethod}
                  </p>
                  <p className=" w-[20%] text-left text-black text-xs font-light py-1">
                    {List?.comments}
                  </p>
                  <div className=' w-[5%] text-left'>
                  <button
                    // onClick={() => handleDelete(List?.productId)}
                    type="button"
                    className="text-yellow-500 cursor-pointer bg-white  border-none"
                  >
                  <Link to={`/payments/payables/edit/amount/${List?.id}`} state={{data:List, from:`/payments/payables/view/${data.payableId}`}}>
                    <Edit/>
                  </Link>
                  </button>
                 
                  <button
                    onClick={() => {
                    //  handledelete(List?.id,List?.paidAmount,List?.balanceAmount,setIsModalOpen(true))
                    handleDelete(List?.id);
                  }}
                    type="button"
                    className="text-yellow-500 cursor-pointer bg-white ml-1 border-none"
                  >
                     <Delete/>
                  </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
          {/* <ModalContainer
                visiable={isModalOpen}
                title="Warning"
                content={`Are you sure, ${List?.paidAmount} will removed from ${List?.balanceAmount}!`}
                handleClick={() => handle(`/payable/delete-payment/${List?.id}`,setIsModalOpen)}
                closeModal={() => setIsModalOpen(false)}
            /> */}
      </div>
  
  )
};
export default ViewPayables;