import React from 'react'
import { SearchButton } from './Buttons'

function SearchBar({handleChange,value}) {
  return (
    <div className=" w-64 md:w-80 h-[34px] relative rounded-md p-1 flex justify-between bg-white items-center" style={{border:'0.5px solid #EAEBED'}}>
    <input type="text" value={value||""} onChange={handleChange} placeholder="Search here" className="placeholder-gray-400 w-[90%] text-sm px-2 outline-none border-none" />
    <SearchButton/>
  </div>
  )
}

export default SearchBar;