import React from 'react'
import { Route } from 'react-router-dom'
import { MainReport, ReportMainIndex } from '../Views/Web/Report'
import ProcurementReportLayout from '../Views/Web/Report/Components/Procurement/ProcurementReportLayout';
import CostSaving from '../Views/Web/Report/Components/Procurement/CostSaving/CostSaving';

function ReportRoutes() {
  return (
    <Route path="reports" element={<MainReport/>}>
      <Route index element={<ReportMainIndex/>} />
      <Route path='procurement' element={<ProcurementReportLayout/>} >
       <Route path='cost-saving-analysis' element={<CostSaving/>}/>
      </Route>
    </Route>
  )
}

export default ReportRoutes;