import React, { useEffect, useState } from "react";
import {
  BackButton,
  CreateButton,
  EditButton,
} from "../../../../Components/Utils/Buttons";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import NuDate from "../../../../Components/Utils/NuDate";
import Modal from "../../../../Components/Utils/AttachmentView";
import { Tab, Tabs } from "../../../../Layouts/MainPage/Tabs";
import axiosInstance from "../../../../Services/axiosCommon";
import CommonComments from "../../../../Components/Utils/commonComments";
import capitalizeFirstLetter from "../../../../Components/Utils/Funcs";
import ApprovalComponent from "../../../../Components/Utils/approvalComponent";
import PRPdfOther from "../../ViewPdfTemplate/PRPdfOther";
import PDFViewer from "../../../../Components/Utils/PDFViewer";
import FilesView from "../../../../Components/Utils/FilesView";
import PDFViewWithAPI from "../../../../Components/Utils/PDFViewWithAPI";
import {
  GetAccess,
  GetNotUseRole,
  GetRole,
} from "../../../../Components/Utils/roles";
import {
  NuDataTime,
  NuInput,
  NuTextArea,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { Formik, useFormik } from "formik";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DeleteIcon from "../../../../Components/Icons/Delete";
import { useDeleteData } from "../../../../Components/Hooks/useDeleteData";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import * as yup from "yup";
import { useToast } from "../../../../Components/Others/ToastServices";
import Attachement, {
  videotype,
} from "../../../../Components/Utils/CustomAttrachmentupload";
import NuAmount from "../../../../Components/Utils/NuAmount";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import Doc from "../../../../Components/Icons/Doc";
import Xls from "../../../../Components/Icons/Xls";
import Txt from "../../../../Components/Icons/Txt";
import Pdf from "../../../../Components/Icons/Pdf";
import Video from "../../../../Components/Icons/Video";
import { GetCurrentSymbol } from "../../../../Components/Utils/OtherFuction";

const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "PDF",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];
function ViewPaymentRequest() {
  const { paymentrequestViewId } = useParams();
  const location = useLocation();
  const pyData = location.state.data;
  const pageIndex = location.state?.pageIndex;
  const searchQuery = location.state?.searchQuery;
  const { value: ModeOfPayment, getData: refModeOfPayment } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "ModeOfPayment_invoicesIncoming"
  );
  const [image, setImage] = useState([]);
  const toast = useToast();

  const [data, setData] = useState(pyData ? pyData : null);

  function getView() {
    axiosInstance
      .get(`/payment-request/${paymentrequestViewId}`)
      .then((res) => {
        setData(res.data?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  useEffect(() => {
    if (!pyData) {
      getView();
    }
  }, [paymentrequestViewId]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteId, setShowDeleteId] = useState(null);

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      setFilePathList(data?.attachments?.length > 0 ? data?.attachments : []);
    }
  }, [data]);

  const [selectedFile, setSelectedFile] = useState(null);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;

        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });

    setImage(tempArr);
  }

  const removeImages = (value) => {
    let List = selectedFile.filter((item) => item?.name !== value);

    setSelectedFile([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;

      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
      tempArr.push(fileName);
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    });
    setImage(tempArr);
  };

  const [selectedPo, setSelectedPo] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [polist, setPolist] = useState([]);
  const [balanceAmount, setBalanceAmount] = useState(data?.totalAmount || 0);
  const [showFileModal, setShowFileModal] = useState(false);
  const [showFileList, setShowFileList] = useState([]);
  const [singleFile, setSingleFile] = useState(null);
  const [singleFileExt, setSingleFileExt] = useState(null);
  // Fetch PO list
  const getPo = () => {
    axiosInstance
      .get(`/payment-request/paid-amount/${paymentrequestViewId}`)
      .then((res) => {
        if (res?.data?.data) {
          setPolist(res.data.data);
          let value = res.data.data[0]?.balanceAmount;
          setBalanceAmount(value);
          setSingleFile(res.data.data[0]?.attachments[0]?.file);
          setSingleFileExt(res.data.data[0]?.attachments[0]?.file_name.split(".").reverse()[0]);
        } else {
          setBalanceAmount(data?.totalAmount);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPo();
  }, [paymentrequestViewId]);

  const popupCalculate = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const { handle } = useDeleteData(paymentrequestViewId);

  const handleDelete = async () => {
    if (showDeleteId) {
      await axiosInstance
        .delete(`/payment-request/paid-amount/${showDeleteId}`)
        .then((res) => {
          toast.open({
            type: "success",
            message: "Success",
            description: res.data?.msg || "Deleted Successfully.",
          });
          getPo();
          getView()
          setShowDelete(false);
        })
        .catch((err) => {
          toast.open({
            type: "error",
            message: "Error",
            description: err?.response?.data?.error || "Network error.",
          });
        });
    }
  };
  const { addFormData } = useAddFormData();

  const [createNew, setCreateNew] = useState({
    paidAmount: "",
    comments: "",
    paymentMethod: "",
    paidOn: "",
  });
  // Formik setup for form values and handleChange
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      paidOn: yup.string().required("Paid On is requred!"),
      paidAmount: yup.string().required("Paid Amount is requred!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      const valueWithItems = {
        ...value,
        // productList: items,
      };
      formData.append("json_data", JSON.stringify(valueWithItems));
      // formData.append('attachments', image.raw);
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      addFormData(
        `/payment-request/pay-amount/${paymentrequestViewId}`,
        formData,
        resetForm,
        false,
        ``,
        true,
        () => {
          getPo();
          getView()
          setIsPopupVisible(false);
        }
      );
    },
  });

  const id = paymentrequestViewId;
  console.log(paymentrequestViewId, "ghgiuhi");

  const getUrl = `/payment-request/get-status`;
  const putUrl = `/payment-request/edit-status`;
  const statusUrl = "/payment-request/all-status";

  return (
    <>
      <div className="w-full h-full py-2 px-4 mb-3 relative">
        <div className="flex justify-between ">
          <Breadcrumb
            items={[
              // { name: "Procurement" },
              {
                name: "Payment Request",
                link: "/procurement/payment-request",
              },

              { name: "View" },
            ]}
          />
          <div className=" flex gap-2">
            <Link
              to={"/procurement/payment-request"}
              state={{
                currentPage: pageIndex,
                searchQuery: searchQuery != "" ? searchQuery : "",
              }}
            >
              <BackButton link={true} />
            </Link>
            {(data?.status === "Approved" || data?.status === "approved") && data?.balanceAmount> 0 && (
              <div>
                <p
                  className="px-3 h-8 cursor-pointer text-xs 2xl:text-sm text-white bg-baseColor-primary rounded-md inline-flex w-auto py-2 flex-shrink-0 items-center justify-center"
                  onClick={popupCalculate}
                >
                  Pay Amount
                </p>
              </div>
            )}
            <Link
              to={`/procurement/payment-request/edit/${paymentrequestViewId}`}
              state={{
                data: data,
                from: `/procurement/payment-request/view/${paymentrequestViewId}`,
              }}
            >
              <EditButton height="8" />
            </Link>
          </div>
        </div>
        <div className="w-full mx-auto h-[calc(100vh-125px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
          <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
            <div className="w-[98%]  bg-white px-1 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">
              <div className="grid  items-center px-1 w-[70%]">
                <div className="flex text-[14px] mb-2 text-[#111537] font-semibold ">
                  <p className="w-[60%] pr-[2px] ">{data?.ref} </p>
                </div>
                <div className="flex gap-4">
                  <p className="text-xs text-[#111537]">
                    Date :{" "}
                    {data?.date ? (
                      <NuDate
                        value={data?.date}
                        format="DD-MMM-YYYY, hh:mm A"
                      />
                    ) : (
                      "N/A"
                    )}
                  </p>

                  <p className="text-xs text-[#111537]">
                    Requested By:{" "}
                    {data?.preparedByName
                      ? capitalizeFirstLetter(data?.preparedByName)
                      : "None"}
                  </p>
                  {/* <p className="bg-[#579BB1] text-white items-center rounded-md text-xs p-[2px]"> Status : {data.status || 'None'}</p> */}
                </div>
              </div>
              <div className="grid  w-[30%]  justify-end ">
                {(data?.status === "Approved" ||
                  data?.status === "approved") && 
                  data?.balanceAmount>0 &&
                  (
                  <div>
                    <p
                      className="cursor-pointer text-white text-xs bg-[#FAB12F] rounded-md inline-flex w-auto h-4 px-3 py-2 flex-shrink-0 items-center justify-center"
                      onClick={popupCalculate}
                    >
                      Pay
                    </p>
                  </div>
                )}
                <div className="border rounded-md mr-1 border-[#579BB1] text-xs inline-flex w-auto  flex-shrink-0  justify-center">
                  <p className="  bg-white  text-black  p-1 rounded-l-md  ">
                    Status{" "}
                  </p>
                  <p className="bg-[#579BB1] text-white p-1 items-center rounded-r-md">
                    {" "}
                    {capitalizeFirstLetter(data?.status || "None")}
                  </p>
                </div>
              </div>
            </div>
            <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8 ">
              <div className=" w-full flex flex-wrap  px-2 border border-gary mt-2  rounded-lg ">
                <div className=" w-full grid grid-cols-2 gap-x-8 ">
                  <div className="w-full flex px-2">
                    <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                      Invoice In.
                    </p>
                    <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                      : {data?.invoiceRef}
                    </p>
                  </div>

                  <div className="w-full flex px-2">
                    <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                      Currency{" "}
                    </p>
                    <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                      : {data?.currency}
                    </p>
                  </div>
                  <div className="w-full flex px-2">
                    <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                      Invoice Amt.
                    </p>
                    <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                      : <NuAmount currency={GetCurrentSymbol(data?.currency)} value={data?.invoiceAmount||0} />
                    </p>
                  </div>

                  <div className="w-full flex px-2">
                    <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                      {" "}
                      Bank Charges{" "}
                    </p>
                    <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                      :  <NuAmount currency={GetCurrentSymbol(data?.currency)} value={data?.bankCharges||0} /> 
                    </p>
                  </div>
                  <div className="w-full flex px-2">
                    <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                      {" "}
                      Total Amt.{" "}
                    </p>
                    <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                      : <NuAmount currency={GetCurrentSymbol(data?.currency)} value={data?.totalAmount||0} /> 
                    </p>
                  </div>
                  <div className="w-full flex px-2">
                    <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                      {" "}
                      Balance Amt.{" "}
                    </p>
                    <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                      : <NuAmount currency={GetCurrentSymbol(data?.currency)} value={data?.balanceAmount||0} /> 
                    </p>
                  </div>
                  <div className="w-full flex px-2">
                    <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                      {" "}
                      Paid Amt.{" "}
                    </p>
                    <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                      : <NuAmount currency={GetCurrentSymbol(data?.currency)} value={data?.paidAmount||0} />
                    </p>
                  </div>
                  <div className="w-full flex px-2">
                    <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                      {" "}
                      Mode of Pmt.{" "}
                    </p>
                    <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                      : {data?.modeOfPayment}
                    </p>
                  </div>
                  {/* <div className="w-full flex px-2">
                  <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                    {" "}
                    Paid Amt. {" "}
                  </p>
                  <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                    : {data?.paidAmount}
                  </p>
                </div> */}
                  <div className="w-full flex px-2">
                    <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                      {" "}
                      Paid On{" "}
                    </p>
                    <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                      :{" "}
                      {data?.paidOn ? (
                        <NuDate value={data?.paidOn} format="DD-MMM-YYYY" />
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </div>
                  <div className="w-full flex px-2">
                    <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                      {" "}
                      Priority{" "}
                    </p>
                    <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                      : {data?.priority}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex gap-4 mt-4">
                <div className="w-[50%] ">
                  <p className="  text-xs font-medium text-[#111537] p-1">
                    Comments
                  </p>
                  <div className="  border border-gary  h-28 overflow-y-scroll rounded-lg items-center">
                    <p className="  text-xs p-2 break-words text-[#606279]">
                      {data.comments || "-"}
                    </p>
                  </div>
                </div>
                <div className="w-[50%] ">
                  <p className=" p-1 text-xs font-medium text-[#111537]">
                    Payment Request Attachments
                  </p>
                  <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">
                    {/* <SmallHeading title={"PR Attachments"} /> */}

                    <div className="w-full flex gap-2 flex-wrap ">
                      {data?.attachments &&
                        filePathList.map((imgs, idx) => {
                          console.log("img", imgs);
                          return <FilesView fileView={imgs} key={idx} />;
                        })}
                    </div>
                  </div>
                </div>
              </div>
              {(data?.status === "Approved" || data?.status === "approved") && (
                <div className="w-full bg-white  border border-gary  mt-4 rounded-lg ">
                  <div className="w-full py-2 bg-baseColor-primary flex justify-between rounded-lg px-2">
                    <p className="w-[5%]  text-left  text-white text-xs font-medium">
                      S.N.{" "}
                    </p>

                    <p className="w-[25%]  text-left text-white text-xs font-medium">
                      Paid Amt.{" "}
                    </p>
                    <p className="w-[20%] text-center text-white text-xs font-medium">
                      Paymt. Method
                    </p>

                    <p className="w-[20%] text-center text-white text-xs font-medium">
                      {" "}
                      Paid On
                    </p>
                    <p className="w-[30%] text-center text-white text-xs font-medium">
                      {" "}
                      Comments
                    </p>
                    <p className="w-[5%]  text-left  text-white text-xs font-medium">
                      Action{" "}
                    </p>
                  </div>
                  {polist.map((List, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100 px-2"
                      >
                        <p className="w-[5%]  text-left   text-xs font-medium text-[#111537] py-1">
                          {index + 1}
                        </p>
                        <p className="w-[25%]  text-left  text-xs font-medium text-[#111537] py-1">
                          <NuAmount value={List?.paidAmount} />
                        </p>
                        <p className="w-[20%] text-center  text-xs font-medium text-[#111537] py-1">
                          {List?.paymentMethod}
                        </p>

                        <p className="w-[20%] text-center  text-xs font-medium text-[#111537] py-1">
                          <NuDate
                            value={List?.paidOn}
                            format="DD-MMM-YYYY hh:mm"
                          />
                        </p>
                        <p className="w-[30%] text-center  text-xs font-medium text-[#111537] py-1">
                          {List?.comments}
                        </p>
                        <div className=" w-[5%] text-left flex ">
                          {List?.attachments?.length > 0 && (
                            <div
                              className="pt-1 cursor-pointer"
                              onClick={() => {
                                setShowFileList(List?.attachments);
                                setShowFileModal(true);
                              }}
                            >
                              <svg
                                weight="15"
                                height="15"
                                viewBox="0 0 12 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                // className="mt-3 mb-2 mx-3"
                              >
                                <path
                                  d="M8.92087 9.46421L8.84249 9.40212L8.78039 9.4805L6.5825 12.2548C5.67329 13.4025 4.00738 13.5956 2.85973 12.6864C1.71208 11.7772 1.51895 10.1113 2.42815 8.96363L7.76469 2.22752C8.03129 1.891 8.42065 1.67417 8.84711 1.62473C9.27358 1.57529 9.70222 1.69729 10.0387 1.96389C10.3753 2.23049 10.5921 2.61985 10.6415 3.04632C10.691 3.47278 10.569 3.90142 10.3024 4.23794L5.81968 9.89627C5.61916 10.1494 5.24744 10.1925 4.99434 9.99195C4.74124 9.79144 4.69815 9.41972 4.89866 9.16662L6.24271 7.47008L6.30481 7.3917L6.22642 7.3296L5.41809 6.68922L5.33971 6.62712L5.27761 6.7055L3.93356 8.40204C3.63403 8.78014 3.49696 9.26173 3.5525 9.74089C3.60805 10.22 3.85167 10.6575 4.22977 10.9571C4.60786 11.2566 5.08946 11.3937 5.56862 11.3381C6.04777 11.2826 6.48524 11.0389 6.78477 10.6609L11.2675 5.00252C12.2453 3.76828 12.0375 1.97659 10.8033 0.99879C9.56908 0.0209951 7.77738 0.228706 6.79959 1.46294L1.46305 8.19905C0.130911 9.88056 0.413639 12.3194 2.09515 13.6515C3.77666 14.9836 6.21545 14.7009 7.5476 13.0194L9.74549 10.2451L9.80759 10.1667L9.7292 10.1046L8.92087 9.46421Z"
                                  fill="#FF2929"
                                  stroke="#FF2929"
                                  stroke-width="0.2"
                                />
                              </svg>
                            </div>
                          )}
                          <button
                            onClick={() => {
                              //  handledelete(List?.id,List?.paidAmount,List?.balanceAmount,setIsModalOpen(true))
                              setShowDeleteId(List?.id);
                              setShowDelete(true);
                              // handleDelete(List?.id);
                            }}
                            type="button"
                            className="text-yellow-500 cursor-pointer  ml-1 border-none"
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}

          <div className="w-[30%] bg-white rounded-lg drop-shadow-lg py-1">
            <div className="h-[98%] overflow-y-scroll p-2 ">
              <ApprovalComponent
                id={id}
                getUrl={getUrl}
                putUrl={putUrl}
                statusUrl={statusUrl}
                refSetData={setData}
                data={data}
                type={"Payment-Request"}
                preUrlName={"Payment-Request"}
              />

              {data?.customerId && GetNotUseRole("procurement") && (
                <>
                  <div className="w-full grid  border border-gary mt-4  rounded-lg">
                    <div className="bg-baseColor-primary  p-2 rounded-t-lg">
                      <p className="text-xs text-white font-medium">
                        YPR Detail
                      </p>
                    </div>
                    <div className=" w-full px-2">
                      <table className=" w-full border-collapse text-left">
                        <TempTrCom
                          label="YPR"
                          value={data?.prRef || ""}
                          subValue={
                            data?.prDate ? (
                              <NuDate
                                value={data?.prDate}
                                format="DD-MMM-YYYY, hh:mm A"
                              />
                            ) : (
                              ""
                            )
                          }
                        />
                        <TempTrCom label="PO" value={data?.poRef || ""} />
                        {data?.vendorName && (
                          <TempTrCom
                            label="Supplier"
                            value={data?.vendorName || ""}
                          />
                        )}
                        {data?.customerName && (
                          <TempTrCom
                            label="Customer"
                            value={data?.customerName || ""}
                          />
                        )}
                      </table>
                    </div>
                  </div>
                </>
              )}

              <div className="w-full h-auto  flex  border border-gary mt-4 overflow-hidden rounded-lg">
                <CommonComments
                  visible={true}
                  // closeModal={handleCloseCommentsModal}
                  title="Comments"
                  mainId={data?.prId}
                  addurl={"/pr-comments/new-comment"}
                  geturl={`/pr-comments/by-PR/${data?.prId}`}
                />
              </div>
            </div>
          </div>

          <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
            {selectedImage?.file.split(".").reverse()[0] == "pdf" ? (
              <PDFViewer pdfFile={selectedImage?.file} />
            ) : (
              <img src={selectedImage?.file} alt={selectedImage?.file} />
            )}
          </Modal>
          {isPopupVisible && (
            <form onSubmit={formik.handleSubmit}>
              <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
                <div className="animate-scale-in w-[35%] max-h-[80vh] bg-white py-4 px-4 rounded-md shadow-lg overflow-y-scroll">
                  <h2 className="text-lg font-medium text-[14px]">
                    Paid Amount
                  </h2>

                  <div className="my-4  w-full ">
                    <NuInput
                      type="number"
                      label="Paid Amount"
                      placeholder="Paid Amount"
                      formik={formik}
                      name="paidAmount"
                      width="w-full "
                      isRequired={true}
                      max={balanceAmount}
                    />
                    <NuDataTime
                      label="Paid On"
                      placeholder="dd/mm/yyyy"
                      formik={formik}
                      name="paidOn"
                      width="w-full "
                      isRequired={true}
                    />
                    <FormikSelectTwo
                      label="Payment Method"
                      placeholder="Choose"
                      Options={ModeOfPayment}
                      formik={formik}
                      name="paymentMethod"
                      width="w-full "
                    />
                    <NuTextArea
                      label="Comments"
                      formik={formik}
                      name="comments"
                      placeholder="Comments"
                      width="w-full "
                      new_width="w-full "
                    />
                    <div className="w-full">
                      <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] ">
                        Attachement :
                      </p>
                    </div>
                    <Attachement
                      handleChange={handleImageChange}
                      preview={image || ""}
                      width="w-full"
                      label="Attach"
                      multiple={true}
                      removeImages={removeImages}
                    />
                  </div>

                  <div className="flex justify-end gap-2">
                    <button
                      type="button"
                      onClick={popupCalculate}
                      className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2]  hover:bg-gray-300
                  rounded-md flex items-center mr-2 text-textColor-main"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      // onClick={popupCalculate}
                      className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      <ModalContainer
        visiable={showDelete}
        title=""
        content={
          <div>
            <p>Are You Sure ! You want to Delete?</p>
          </div>
        }
        handleClick={handleDelete}
        closeModal={() => {setShowDelete(false)}}
        // isLoading={loading}
      />
      <Modal show={showFileModal} onClose={() => {
        setShowFileModal(false)
        setSingleFile(null);
        setSingleFileExt(null);
        }}
        height="h-[calc(100vh-10px)]"
        width="w-[98%]"
        >
        <div className="w-full h-[calc(100vh-85px)]">
          <div className="w-full h-[calc(100vh-113px)]">
            <div className=" w-full h-full flex justify-center">
              {singleFileExt === ("docx" || "doc" || "txt") ? (
                <iframe
                  src={singleFile} // PDF URL or Blob URL
                  style={{ width: "100%", height: "100%" }}
                  frameBorder="0"
                />
              ) : singleFileExt === ("xlsx" || "xls") ? (
                <iframe
                  src={singleFile} // PDF URL or Blob URL
                  style={{ width: "100%", height: "100%" }}
                  frameBorder="0"
                />
              ) : singleFileExt === "txt" ? (
                <iframe
                  src={singleFile} // PDF URL or Blob URL
                  style={{ width: "100%", height: "100%" }}
                  frameBorder="0"
                />
              ) : singleFileExt === "pdf" ? (
                <iframe
                  src={singleFile} // PDF URL or Blob URL
                  style={{ width: "100%", height: "100%" }}
                  frameBorder="0"
                />
              ) : singleFileExt === "PDF" ? (
                <iframe
                  src={singleFile} // PDF URL or Blob URL
                  style={{ width: "100%", height: "100%" }}
                  frameBorder="0"
                />
              ) : singleFileExt === "pptx" ? (
                <>No file</>
              ) : (
                <img src={singleFile} alt="#" width="100%" className=" w-auto h-[calc(100vh-144px)]" />
              )}
            </div>
          </div>
          <div className="w-full h-11 flex justify-center items-center">
            {showFileList &&
              showFileList.map((item, index) => {
                let Ext = item?.file_name.split(".").reverse()[0];
                return (
                  <div
                    className="w-11 h-11 cursor-pointer bg-white flex justify-center items-center mx-1"
                    key={index}
                    onClick={() => {
                      setSingleFile(item?.file);
                      setSingleFileExt(Ext);
                    }}
                  >
                    {videotype.includes(Ext) ? <Video /> : null}

                    {Ext === ("docx" || "doc" || "txt") ? (
                      <Doc />
                    ) : Ext === ("xlsx" || "xls") ? (
                      <Xls />
                    ) : Ext === "txt" ? (
                      <Txt />
                    ) : Ext === "pdf" ? (
                      <Pdf />
                    ) : Ext === "PDF" ? (
                      <Pdf />
                    ) : Ext === "pptx" ? (
                      <>No file</>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="w-6 h-6 "
                        fill="#3B82F6"
                      >
                        <path d="M448 80c8.8 0 16 7.2 16 16l0 319.8-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3L48 96c0-8.8 7.2-16 16-16l384 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                      </svg>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ViewPaymentRequest;

function TempTrCom({ label = "", value = "", subValue = "" }) {
  return (
    <tr className="w-full">
      <td className="w-[70px]">
        <p className="py-2 text-xs font-medium text-[#111537]">{label}</p>
      </td>
      <td className="w-[10px]">:</td>
      <td className="flex-1 py-2">
        <p className="text-xs text-[#606279] truncate text-wrap">{value}</p>
        {subValue && (
          <p className="text-xs text-[#606279] truncate text-wrap">
            {subValue}
          </p>
        )}
      </td>
    </tr>
  );
}
