export const currencySymbol = [
    {
        label: "EUR",
        value: "€",
        name: "Euro",
        labelWithName: "EUR - Euro",
        valueWithName: "€ - Euro"
    }, 
    {
        label: "ALL",
        value: "L",
        name: "Albanian lek",
        labelWithName: "ALL - Albanian lek",
        valueWithName: "L - Albanian lek"
    },
    {
        label: "BYN",
        value: "Br",
        name: "Belarusian ruble",
        labelWithName: "BYN - Belarusian ruble",
        valueWithName: "Br - Belarusian ruble"
    }, 
    {
        label: "BAM",
        value: "KM",
        name: "Bosnia and Herzegovina convertible mark",
        labelWithName: "BAM - Bosnia and Herzegovina convertible mark",
        valueWithName: "KM - Bosnia and Herzegovina convertible mark"
    }, 
    {
        label: "BGN",
        value: "лв",
        name: "Bulgarian lev",
        labelWithName: "BGN - Bulgarian lev",
        valueWithName: "лв - Bulgarian lev"
    }, 
    {
        label: "HRK",
        value: "kn",
        name: "Croatian kuna",
        labelWithName: "HRK - Croatian kuna",
        valueWithName: "kn - Croatian kuna"
    }, 
    {
        label: "CZK",
        value: "Kč",
        name: "Czech koruna",
        labelWithName: "CZK - Czech koruna",
        valueWithName: "Kč - Czech koruna"
    },
    {
        label: "DKK",
        value: "kr",
        name: "Danish krone",
        labelWithName: "DKK - Danish krone",
        valueWithName: "kr - Danish krone"
    }, 
    {
        label: "GEL",
        value: "₾",
        name: "Georgian lari",
        labelWithName: "GEL - Georgian lari",
        valueWithName: "₾ - Georgian lari"
    }, 
    {
        label: "HUF",
        value: "ft",
        name: "Hungarian forint",
        labelWithName: "HUF - Hungarian forint",
        valueWithName: "ft - Hungarian forint"
    },  
    {
        label: "ISK",
        value: "Íkr",
        name: "Icelandic króna",
        labelWithName: "ISK - Icelandic króna",
        valueWithName: "Íkr - Icelandic króna"
    },
    {
        label: "CHF",
        value: "CHF",
        name: "Swiss franc",
        labelWithName: "CHF - Swiss franc",
        valueWithName: "CHF - Swiss franc"
    },
    {
        label: "MDL",
        value: "L",
        name: "Moldovan leu",
        labelWithName: "MDL - Moldovan leu",
        valueWithName: "L - Moldovan leu"
    },
    {
        label: "MKD",
        value: "ден",
        name: "Second Macedonian denar",
        labelWithName: "MKD - Second Macedonian denar",
        valueWithName: "ден - Second Macedonian denar"
    },
    {
        label: "NOK",
        value: "kr",
        name: "Norwegian krone",
        labelWithName: "NOK - Norwegian krone",
        valueWithName: "kr - Norwegian krone"
    },
    {
        label: "PLN",
        value: "zł",
        name: "Polish zloty",
        labelWithName: "PLN - Polish zloty",
        valueWithName: "zł - Polish zloty"
    },
    {
        label: "RON",
        value: "lei",
        name: "Romanian leu",
        labelWithName: "RON - Romanian leu",
        valueWithName: "lei - Romanian leu"
    },
    {
        label: "RUB",
        value: "₽",
        name: "Russian ruble",
        labelWithName: "RUB - Russian ruble",
        valueWithName: "₽ - Russian ruble"
    },
    {
        label: "RSD",
        value: "RSD",
        name: "Serbian dinar",
        labelWithName: "RSD - Serbian dinar",
        valueWithName: "RSD - Serbian dinar"
    },
    {
        label: "SEK",
        value: "kr",
        name: "Swedish krona",
        labelWithName: "SEK - Swedish krona",
        valueWithName: "kr - Swedish krona"
    },
    {
        label: "TRY",
        value: "₺",
        name: "Turkish lira",
        labelWithName: "TRY - Turkish lira",
        valueWithName: "₺ - Turkish lira"
    },
    {
        label: "UAH",
        value: "₴",
        name: "Ukrainian hryvna",
        labelWithName: "UAH - Ukrainian hryvna",
        valueWithName: "₴ - Ukrainian hryvna"
    },
    {
        label: "GBP",
        value: "£",
        name: "Pounds sterling",
        labelWithName: "GBP - Pounds sterling",
        valueWithName: "£ - Pounds sterling"
    },
    {
        label: "USD",
        value: "$",
        name: "United States Dollar",
        labelWithName: "USD - United States Dollar",
        valueWithName: "$ - United States Dollar"
    },
    {
        label: "XCD",
        value: "$",
        name: "East Caribbean dollar",
        labelWithName: "XCD - East Caribbean dollar",
        valueWithName: "$ - East Caribbean dollar"
    },
    {
        label: "AWG",
        value: "ƒ",
        name: "Aruban florin",
        labelWithName: "AWG - Aruban florin",
        valueWithName: "ƒ - Aruban florin"
    },
    {
        label: "ARS",
        value: "$",
        name: "Argentine peso",
        labelWithName: "ARS - Argentine peso",
        valueWithName: "$ - Argentine peso"
    },
    {
        label: "BSD",
        value: "B$",
        name: "Bahamian dollar",
        labelWithName: "BSD - Bahamian dollar",
        valueWithName: "B$ - Bahamian dollar"
    },
    {
        label: "BBD",
        value: "$",
        name: "Barbadian dollar",
        labelWithName: "BBD - Barbadian dollar",
        valueWithName: "$ - Barbadian dollar"
    },
    {
        label: "BMD",
        value: "$",
        name: "Bermudian dollar",
        labelWithName: "BMD - Bermudian dollar",
        valueWithName: "$ - Bermudian dollar"
    },
    {
        label: "BZD",
        value: "BZ$",
        name: "Belize dollar",
        labelWithName: "BZD - Belize dollar",
        valueWithName: "BZ$ - Belize dollar"
    },
    {
        label: "BOB",
        value: "Bs",
        name: "Bolivian boliviano",
        labelWithName: "BOB - Bolivian boliviano",
        valueWithName: "Bs - Bolivian boliviano"
    },
    {
        label: "BRL",
        value: "R$",
        name: "Brazilian real",
        labelWithName: "BRL - Brazilian real",
        valueWithName: "R$ - Brazilian real"
    },
    {
        label: "CAD",
        value: "CA$",
        name: "Canadian dollar",
        labelWithName: "CAD - Canadian dollar",
        valueWithName: "CA$ - Canadian dollar"
    },
    {
        label: "KYD",
        value: "CI$",
        name: "Cayman Islands dollar",
        labelWithName: "KYD - Cayman Islands dollar",
        valueWithName: "CI$ - Cayman Islands dollar"
    },
    {
        label: "CLP",
        value: "$",
        name: "Chilean peso",
        labelWithName: "CLP - Chilean peso",
        valueWithName: "$ - Chilean peso"
    },
    {
        label: "COP",
        value: "$",
        name: "Colombian peso",
        labelWithName: "COP - Colombian peso",
        valueWithName: "$ - Colombian peso"
    },
    {
        label: "CRC",
        value: "₡",
        name: "Costa Rican colón",
        labelWithName: "CRC - Costa Rican colón",
        valueWithName: "₡ - Costa Rican colón"
    },
    {
        label: "CUP",
        value: "CUC$",
        name: "Cuban peso",
        labelWithName: "CUP - Cuban peso",
        valueWithName: "CUC$ - Cuban peso"
    },
    {
        label: "DOP",
        value: "RD$",
        name: "Dominican peso",
        labelWithName: "DOP - Dominican peso",
        valueWithName: "RD$ - Dominican peso"
    },
    {
        label: "XCD",
        value: "$",
        name: "East Caribbean dollar",
        labelWithName: "XCD - East Caribbean dollar",
        valueWithName: "$ - East Caribbean dollar"
    },
    {
        label: "FJD",
        value: "FJ$",
        name: "Fijian dollar",
        labelWithName: "FJD - Fijian dollar",
        valueWithName: "FJ$ - Fijian dollar"
    },
    {
        label: "GTQ",
        value: "Q",
        name: "Guatemalan quetzal",
        labelWithName: "GTQ - Guatemalan quetzal",
        valueWithName: "Q - Guatemalan quetzal"
    },
    {
        label: "GYD",
        value: "G$",
        name: "Guyanese dollar",
        labelWithName: "GYD - Guyanese dollar",
        valueWithName: "G$ - Guyanese dollar"
    },
    {
        label: "HNL",
        value: "L",
        name: "Honduran lempira",
        labelWithName: "HNL - Honduran lempira",
        valueWithName: "L - Honduran lempira"
    },
    {
        label: "JMD",
        value: "J$",
        name: "Jamaican dollar",
        labelWithName: "JMD - Jamaican dollar",
        valueWithName: "J$ - Jamaican dollar"
    },
    {
        label: "MXN",
        value: "$",
        name: "Mexican peso",
        labelWithName: "MXN - Mexican peso",
        valueWithName: "$ - Mexican peso"
    },
    {
        label: "NIO",
        value: "C$",
        name: "Nicaraguan córdoba",
        labelWithName: "NIO - Nicaraguan córdoba",
        valueWithName: "C$ - Nicaraguan córdoba"
    },
    {
        label: "PAB",
        value: "B/.",
        name: "Panamanian balboa",
        labelWithName: "PAB - Panamanian balboa",
        valueWithName: "B/. - Panamanian balboa"
    },
    {
        label: "PYG",
        value: "₲",
        name: "Paraguayan guaraní",
        labelWithName: "PYG - Paraguayan guaraní",
        valueWithName: "₲ - Paraguayan guaraní"
    },
    {
        label: "PEN",
        value: "S/",
        name: "Peruvian nuevo sol",
        labelWithName: "PEN - Peruvian nuevo sol",
        valueWithName: "S/ - Peruvian nuevo sol"
    },
    {
        label: "SRD",
        value: "$",
        name: "Surinamese dollar",
        labelWithName: "SRD - Surinamese dollar",
        valueWithName: "$ - Surinamese dollar"
    },
    {
        label: "TTD",
        value: "TT$",
        name: "Trinidad and Tobago dollar",
        labelWithName: "TTD - Trinidad and Tobago dollar",
        valueWithName: "TT$ - Trinidad and Tobago dollar"
    },
    {
        label: "UYU",
        value: "$U",
        name: "Uruguayan peso",
        labelWithName: "UYU - Uruguayan peso",
        valueWithName: "$U - Uruguayan peso"
    },
    {
        label: "VEF",
        value: "Bs",
        name: "Venezuelan bolívar",
        labelWithName: "VEF - Venezuelan bolívar",
        valueWithName: "Bs - Venezuelan bolívar"
    },
    {
        label: "AFN",
        value: "AFN",
        name: "Afghan afghani",
        labelWithName: "AFN - Afghan afghani",
        valueWithName: "AFN - Afghan afghani"
    },
    {
        label: "SAR",
        value: "SAR",
        name: "Saudi riyal",
        labelWithName: "SAR - Saudi riyal",
        valueWithName: "SAR - Saudi riyal"
    },
    {
        label: "IRR",
        value: "IRR",
        name: "Iranian rial",
        labelWithName: "IRR - Iranian rial",
        valueWithName: "IRR - Iranian rial"
    },
    {
        label: "IQD",
        value: "IQD",
        name: "Iraqi dinar",
        labelWithName: "IQD - Iraqi dinar",
        valueWithName: "IQD - Iraqi dinar"
    },
    {
        label: "ILS",
        value: "ILS",
        name: "Israeli new shekel",
        labelWithName: "ILS - Israeli new shekel",
        valueWithName: "ILS - Israeli new shekel"
    },
    {
        label: "JOD",
        value: "JOD",
        name: "Jordanian dinar",
        labelWithName: "JOD - Jordanian dinar",
        valueWithName: "JOD - Jordanian dinar"
    },
    {
        label: "KWD",
        value: "KWD",
        name: "Kuwaiti dinar",
        labelWithName: "KWD - Kuwaiti dinar",
        valueWithName: "KWD - Kuwaiti dinar"
    },
    {
        label: "LBP",
        value: "LBP",
        name: "Lebanese pound",
        labelWithName: "LBP - Lebanese pound",
        valueWithName: "LBP - Lebanese pound"
    },
    {
        label: "OMR",
        value: "OMR",
        name: "Omani rial",
        labelWithName: "OMR - Omani rial",
        valueWithName: "OMR - Omani rial"
    },
    {
        label: "QAR",
        value: "QAR",
        name: "Qatari riyal",
        labelWithName: "QAR - Qatari riyal",
        valueWithName: "QAR - Qatari riyal"
    },
    {
        label: "SYP",
        value: "£S",
        name: "Syrian pound",
        labelWithName: "SYP - Syrian pound",
        valueWithName: "£S - Syrian pound"
    },
    {
        label: "YER",
        value: "YER",
        name: "Yemeni rial",
        labelWithName: "YER - Yemeni rial",
        valueWithName: "YER - Yemeni rial"
    },
    {
        label: "CNY",
        value: "¥",
        name: "Chinese yuan",
        labelWithName: "CNY - Chinese yuan",
        valueWithName: "¥ - Chinese yuan"
    },
    {
        label: "JPY",
        value: "¥",
        name: "Japanese yen",
        labelWithName: "JPY - Japanese yen",
        valueWithName: "¥ - Japanese yen"
    },
    {
        label: "KRW",
        value: "₩",
        name: "South Korean won",
        labelWithName: "KRW - South Korean won",
        valueWithName: "₩ - South Korean won",
    },
    {
        label: "HKD",
        value: "HK$",
        name: "Hong Kong dollar",
        labelWithName: "HKD - Hong Kong dollar",
        valueWithName: "HK$ - Hong Kong dollar"
    },
    {
        label: "TWD",
        value: "NT$",
        name: "New Taiwan dollar",
        labelWithName: "TWD - New Taiwan dollar",
        valueWithName: "NT$ - New Taiwan dollar"
    },
    {
        label: "SGD",
        value: "S$",
        name: "Singapore dollar",
        labelWithName: "SGD - Singapore dollar",
        valueWithName: "S$ - Singapore dollar"
    },
    {
        label: "AUD",
        value: "A$",
        name: "Australian dollar",
        labelWithName: "AUD - Australian dollar",
        valueWithName: "A$ - Australian dollar"
    },
    {
        label: "NZD",
        value: "NZ$",
        name: "New Zealand dollar",
        labelWithName: "NZD - New Zealand dollar",
        valueWithName: "NZ$ - New Zealand dollar"
    },
    {
        label: "IDR",
        value: "Rp",
        name: "Indonesian rupiah",
        labelWithName: "IDR - Indonesian rupiah",
        valueWithName: "Rp - Indonesian rupiah"
    },
    {
        label: "MYR",
        value: "RM",
        name: "Malaysian ringgit",
        labelWithName: "MYR - Malaysian ringgit",
        valueWithName: "RM - Malaysian ringgit",
    },
    {
        label: "PHP",
        value: "₱",
        name: "Philippine peso",
        labelWithName: "PHP - Philippine peso",
        valueWithName: "₱ - Philippine peso"
    },
    {
        label: "THB",
        value: "฿",
        name: "Thai baht",
        labelWithName: "THB - Thai baht",
        valueWithName: "฿ - Thai baht"
    },
    {
        label: "VND",
        value: "₫",
        name: "Vietnamese dong",
        labelWithName: "VND - Vietnamese dong",
        valueWithName: "₫ - Vietnamese dong"
    },
    {
        label: "INR",
        value: "₹",
        name: "Indian rupee",
        labelWithName: "INR - Indian rupee",
        valueWithName: "₹ - Indian rupee"
    },
    {
        label: "PKR",
        value: "₨",
        name: "Pakistani rupee",
        labelWithName: "PKR - Pakistani rupee",
        valueWithName: "₨ - Pakistani rupee",
    },
    {
        label: "BDT",
        value: "৳",
        name: "Bangladeshi taka",
        labelWithName: "BDT - Bangladeshi taka",
        valueWithName: "৳ - Bangladeshi taka"
    },
    {
        label: "LKR",
        value: "Rs",
        name: "Sri Lankan rupee",
        labelWithName: "LKR - Sri Lankan rupee",
        valueWithName: "Rs - Sri Lankan rupee"
    },
    {
        label: "NPR",
        value: "Rs",
        name: "Nepalese rupee",
        labelWithName: "NPR - Nepalese rupee",
        valueWithName: "Rs - Nepalese rupee"
    },
    {
        label: "MVR",
        value: "Rf",
        name: "Maldivian rufiyaa",
        labelWithName: "MVR - Maldivian rufiyaa",
        valueWithName: "Rf - Maldivian rufiyaa"
    },
    {
        label: "MMK",
        value: "K",
        name: "Burmese kyat",
        labelWithName: "MMK - Burmese kyat",
        valueWithName: "K - Burmese kyat"
    },
    {
        label: "KHR",
        value: "៛",
        name: "Cambodian riel",
        labelWithName: "KHR - Cambodian riel",
        valueWithName: "៛ - Cambodian riel"
    },
    {
        label: "LAK",
        value: "₭",
        name: "Lao kip",
        labelWithName: "LAK - Lao kip",
        valueWithName: "₭ - Lao kip"
    },
    {
        label: "KZT",
        value: "₸",
        name: "Kazakhstani tenge",
        labelWithName: "KZT - Kazakhstani tenge",
        valueWithName: "₸ - Kazakhstani tenge"
    },
    {
        label: "UZS",
        value: "лв",
        name: "Uzbekistani som",
        labelWithName: "UZS - Uzbekistani som",
        valueWithName: "лв - Uzbekistani som"
    },
    {
        label: "TJS",
        value: "смн",
        name: "Tajikistani somoni",
        labelWithName: "TJS - Tajikistani somoni",
        valueWithName: "смн - Tajikistani somoni"
    },
    {
        label: "AZN",
        value: "ман",
        name: "Azerbaijani manat",
        labelWithName: "AZN - Azerbaijani manat",
        valueWithName: "ман - Azerbaijani manat"
    },
]