export const userRole = [
  {
    name: "Products",
    roles: [
      {
        id: 1,
        name: "Item View",
      },
      {
        id: 2,
        name: "Item Create",
      },
      {
        id: 3,
        name: "Item Edit",
      },
      {
        id: 4,
        name: "Item Delete",
      },
    ],
  },
  {
    name: "Marketing",
    roles: [
      {
        id: 5,
        name: "Appointments View",
      },
      {
        id: 6,
        name: "Appointments Create",
      },
      {
        id: 7,
        name: "Appointments Edit",
      },
      {
        id: 8,
        name: "Appointments Delete",
      },
      {
        id: 9,
        name: "Leads View",
      },
      {
        id: 10,
        name: "Leads Create",
      },
      {
        id: 11,
        name: "Leads Edit",
      },
      {
        id: 12,
        name: "Leads Delete",
      },
    ],
  },
  {
    name: "Procurement",
    roles: [
      {
        id: 13,
        name: "Purchase Requests View",
      },
      {
        id: 14,
        name: "Purchase Requests Create",
      },
      {
        id: 15,
        name: "Purchase Requests Edit",
      },
      {
        id: 16,
        name: "Purchase Requests Delete",
      },
      {
        id: 17,
        name: "RFQ View",
      },
      {
        id: 18,
        name: "RFQ Create",
      },
      {
        id: 19,
        name: "RFQ Edit",
      },
      {
        id: 20,
        name: "RFQ Delete",
      },
      {
        id: 21,
        name: "Supplier Quote View",
      },
      {
        id: 22,
        name: "Supplier Quote Create",
      },
      {
        id: 23,
        name: "Supplier Quote Edit",
      },
      {
        id: 24,
        name: "Supplier Quote Delete",
      },
      {
        id: 25,
        name: "Quote Compare View",
      },
      // {
      //   id: 26,
      //   name: "Quote Compare Edit",
      // },
      {
        id: 26,
        name: "Purchase Order View",
      },
      {
        id: 27,
        name: "Purchase Order Create",
      },
      {
        id: 28,
        name: "Purchase Order Edit",
      },
      {
        id: 29,
        name: "Purchase Order Delete",
      },
      // {
      //   id: 39,
      //   name: "Contracts View",
      // },
      // {
      //   id: 40,
      //   name: "Contracts Create",
      // },
      // {
      //   id: 41,
      //   name: "Contracts Edit",
      // },
      // {
      //   id: 42,
      //   name: "Contracts Delete",
      // },
      {
        id: 30,
        name: "Suppliers View",
      },
      {
        id: 31,
        name: "Suppliers Create",
      },
      {
        id: 32,
        name: "Suppliers Edit",
      },
      {
        id: 33,
        name: "Suppliers Delete",
      },
      {
        id: 182,
        name: "Payment Request View",
      },
      {
        id: 183,
        name: "Payment Request Create",
      },
      {
        id: 184,
        name: "Payment Request Edit",
      },
      {
        id: 185,
        name: "Payment Request Delete",
      },
      {
        id: 186,
        name: "Team Performance View",
      },
    ],
  },
  {
    name: "Sales",
    roles: [
      {
        id: 34,
        name: "Customers View",
      },
      {
        id: 35,
        name: "Customers Create",
      },
      {
        id: 36,
        name: "Customers Edit",
      },
      {
        id: 37,
        name: "Customers Delete",
      },
      {
        id: 38,
        name: "Invoice In View",
      },
      {
        id: 39,
        name: "Invoice In Create",
      },
      {
        id: 40,
        name: "Invoice In Edit",
      },
      {
        id: 41,
        name: "Invoice In Delete",
      },
      {
        id: 42,
        name: "Invoice Out View",
      },
      {
        id: 43,
        name: "Invoice Out Create",
      },
      {
        id: 44,
        name: "Invoice Out Edit",
      },
      {
        id: 45,
        name: "Invoice Out Delete",
      },
      {
        id: 46,
        name: "Promotions View",
      },
      {
        id: 47,
        name: "Promotions Create",
      },
      {
        id: 48,
        name: "Promotions Edit",
      },
      {
        id: 49,
        name: "Promotions Delete",
      },
      {
        id: 50,
        name: "Sales Order View",
      },
      {
        id: 51,
        name: "Sales Order Create",
      },
      {
        id: 52,
        name: "Sales Order Edit",
      },
      {
        id: 53,
        name: "Sales Order Delete",
      },
      {
        id: 54,
        name: "Customer Quote View",
      },
      {
        id: 55,
        name: "Customer Quote Create",
      },
      {
        id: 56,
        name: "Customer Quote Edit",
      },
      {
        id: 57,
        name: "Customer Quote Delete",
      },
      // {
      //   id: 21,
      //   name: "Customer Order View",
      // },
      // {
      //   id: 22,
      //   name: "Customer Order Create",
      // },
      // {
      //   id: 23,
      //   name: "Customer Order Edit",
      // },
      // {
      //   id: 24,
      //   name: "Customer Order Delete",
      // },
    ],
  },
  {
    name: "Payments",
    roles: [
      {
        id: 58,
        name: "Payables View",
      },
      {
        id: 59,
        name: "Payables Create",
      },
      {
        id: 60,
        name: "Payables Edit",
      },
      {
        id: 61,
        name: "Payables Delete",
      },
      {
        id: 62,
        name: "Receivables View",
      },
      {
        id: 63,
        name: "Receivables Create",
      },
      {
        id: 64,
        name: "Receivables Edit",
      },
      {
        id: 65,
        name: "Receivables Delete",
      },
      // {
      //   id: 79,
      //   name: "Refunds View",
      // },
      // {
      //   id: 80,
      //   name: "Refunds Create",
      // },
      // {
      //   id: 81,
      //   name: "Refunds Edit",
      // },
      // {
      //   id: 82,
      //   name: "Refunds Delete",
      // },
    ],
  },
  // {
  //   name: "Task",
  //   roles: [
  //     {
  //       id: 83,
  //       name: "Task View",
  //     },
  //     {
  //       id: 84,
  //       name: "Task Create",
  //     },
  //     {
  //       id: 85,
  //       name: "Task Edit",
  //     },
  //     {
  //       id: 86,
  //       name: "Task Delete",
  //     },
  //   ],
  // },
  {
    name: "Store",
    roles: [
      {
        id: 66,
        name: "Assets View",
      },
      {
        id: 67,
        name: "Assets Create",
      },
      {
        id: 68,
        name: "Assets Edit",
      },
      {
        id: 69,
        name: "Assets Delete",
      },
      {
        id: 70,
        name: "inventory View",
      },
      {
        id: 71,
        name: "inventory Create",
      },
      {
        id: 72,
        name: "inventory Edit",
      },
      {
        id: 73,
        name: "inventory Delete",
      },
      {
        id: 74,
        name: "GRN View",
      },
      {
        id: 75,
        name: "GRN Create",
      },
      {
        id: 76,
        name: "GRN Edit",
      },
      {
        id: 77,
        name: "GRN Delete",
      },
      {
        id: 78,
        name: "Delivery Notes View",
      },
      {
        id: 79,
        name: "Delivery Notes Create",
      },
      {
        id: 80,
        name: "Delivery Notes Edit",
      },
      {
        id: 81,
        name: "Delivery Notes Delete",
      },
      {
        id: 82,
        name: "Returns View",
      },
      {
        id: 83,
        name: "Returns Create",
      },
      {
        id: 84,
        name: "Returns Edit",
      },
      {
        id: 85,
        name: "Returns Delete",
      },
      {
        id: 86,
        name: "Packing List View",
      },
      {
        id: 87,
        name: "Packing List Create",
      },
      {
        id: 88,
        name: "Packing List Edit",
      },
      {
        id: 89,
        name: "Packing List Delete",
      },
      {
        id: 90,
        name: "Requests View",
      },
      {
        id: 91,
        name: "Requests Create",
      },
      {
        id: 92,
        name: "Requests Edit",
      },
      {
        id: 93,
        name: "Requests Delete",
      },
    ],
  },
  {
    name: "Shipping",
    roles: [
      {
        id: 94,
        name: "Agents View",
      },
      {
        id: 95,
        name: "Agents Create",
      },
      {
        id: 96,
        name: "Agents Edit",
      },
      {
        id: 97,
        name: "Agents Delete",
      },
      {
        id: 98,
        name: "Shipment Tracking View",
      },
      {
        id: 99,
        name: "Shipment Tracking Create",
      },
      {
        id: 100,
        name: "Shipment Tracking Edit",
      },
      {
        id: 101,
        name: "Shipment Tracking Delete",
      },
    ],
  },
  {
    name: "Maintenance",
    roles: [
      {
        id: 102,
        name: "vehicle View",
      },
      {
        id: 103,
        name: "vehicle Create",
      },
      {
        id: 104,
        name: "vehicle Edit",
      },
      {
        id: 105,
        name: "vehicle Delete",
      },
      {
        id: 106,
        name: "Equipment View",
      },
      {
        id: 107,
        name: "Equipment Create",
      },
      {
        id: 108,
        name: "Equipment Edit",
      },
      {
        id: 109,
        name: "Equipment Delete",
      },
    ],
  },
  // {
  //   name: "Chat",
  //   roles: [
  //     {
  //       id: 131,
  //       name: "Chat View",
  //     },
  //     {
  //       id: 132,
  //       name: "Chat Create",
  //     },
  //     {
  //       id: 133,
  //       name: "Chat Edit",
  //     },
  //     {
  //       id: 134,
  //       name: "Chat Delete",
  //     },
  //   ],
  // },
  // {
  //   name: "Team",
  //   roles: [
  //     {
  //       id: 135,
  //       name: "Team View",
  //     },
  //     {
  //       id: 136,
  //       name: "Team Create",
  //     },
  //     {
  //       id: 137,
  //       name: "Team Edit",
  //     },
  //     {
  //       id: 138,
  //       name: "Team Delete",
  //     },
  //   ],
  // },
  {
    name: "Report",
    roles: [
      {
        id: 110,
        name: "Report View",
      },
      {
        id: 111,
        name: "Report Create",
      },
      {
        id: 112,
        name: "Report Edit",
      },
      {
        id: 113,
        name: "Report Delete",
      },
    ],
  },

  {
    name: "Settings",
    roles: [
      {
        id: 150,
        name: "Settings View",
      },
      {
        id: 151,
        name: "Settings Create",
      },
      {
        id: 152,
        name: "Settings Edit",
      },
      {
        id: 153,
        name: "Settings Delete",
      },
      {
        id: 114,
        name: "Company Info View",
      },
      {
        id: 115,
        name: "Company Info Create",
      },
      {
        id: 116,
        name: "Company Info Edit",
      },
      {
        id: 117,
        name: "Company Info Delete",
      },
      {
        id: 118,
        name: "Product Settings View",
      },
      {
        id: 119,
        name: "Product Settings Create",
      },
      {
        id: 120,
        name: "Product Settings Edit",
      },
      {
        id: 121,
        name: "Product Settings Delete",
      },
      {
        id: 122,
        name: "Marketing Settings View",
      },
      {
        id: 123,
        name: "Marketing Settings Create",
      },
      {
        id: 124,
        name: "Marketing Settings Edit",
      },
      {
        id: 125,
        name: "Marketing Settings Delete",
      },
      {
        id: 126,
        name: "Procurement Settings View",
      },
      {
        id: 127,
        name: "Procurement Settings Create",
      },
      {
        id: 128,
        name: "Procurement Settings Edit",
      },
      {
        id: 129,
        name: "Procurement Settings Delete",
      },
      {
        id: 130,
        name: "Sales Settings View",
      },
      {
        id: 131,
        name: "Sales Settings Create",
      },
      {
        id: 132,
        name: "Sales Settings Edit",
      },
      {
        id: 133,
        name: "Sales Settings Delete",
      },
      {
        id: 134,
        name: "Payment Settings View",
      },
      {
        id: 135,
        name: "Payment Settings Create",
      },
      {
        id: 136,
        name: "Payment Settings Edit",
      },
      {
        id: 137,
        name: "Payment Settings Delete",
      },
      // {
      //   id: 167,
      //   name: "Task Settings View",
      // },
      // {
      //   id: 168,
      //   name: "Task Settings Create",
      // },
      // {
      //   id: 169,
      //   name: "Task Settings Edit",
      // },
      // {
      //   id: 170,
      //   name: "Task Settings Delete",
      // },
      {
        id: 138,
        name: "Store Settings View",
      },
      {
        id: 139,
        name: "Store Settings Create",
      },
      {
        id: 140,
        name: "Store Settings Edit",
      },
      {
        id: 141,
        name: "Store Settings Delete",
      },
      {
        id: 142,
        name: "Shipping Settings View",
      },
      {
        id: 143,
        name: "Shipping Settings Create",
      },
      {
        id: 144,
        name: "Shipping Settings Edit",
      },
      {
        id: 145,
        name: "Shipping Settings Delete",
      },
      {
        id: 146,
        name: "Maintenance Settings View",
      },
      {
        id: 147,
        name: "Maintenance Settings Create",
      },
      {
        id: 148,
        name: "Maintenance Settings Edit",
      },
      {
        id: 149,
        name: "Maintenance Settings Delete",
      },
      {
        id: 154,
        name: "User Settings View",
      },
      {
        id: 155,
        name: "User Settings Create",
      },
      {
        id: 156,
        name: "User Settings Edit",
      },
      {
        id: 157,
        name: "User Settings Delete",
      },
      {
        id: 158,
        name: "Role Settings View",
      },
      {
        id: 159,
        name: "Role Settings Create",
      },
      {
        id: 160,
        name: "Role Settings Edit",
      },
      {
        id: 161,
        name: "Role Settings Delete",
      },
      {
        id: 162,
        name: "User Role Settings View",
      },
      {
        id: 163,
        name: "User Role Settings Create",
      },
      {
        id: 164,
        name: "User Role Settings Edit",
      },
      {
        id: 165,
        name: "User Role Settings Delete",
      },
      {
        id: 166,
        name: "Email Server Settings View",
      },
      {
        id: 167,
        name: "Email Server Settings Create",
      },
      {
        id: 168,
        name: "Email Server Settings Edit",
      },
      {
        id: 169,
        name: "Email Server Settings Delete",
      },
      {
        id: 170,
        name: "Email Template Settings View",
      },
      {
        id: 171,
        name: "Email Template Settings Create",
      },
      {
        id: 172,
        name: "Email Template Settings Edit",
      },
      {
        id: 173,
        name: "Email Template Settings Delete",
      },
      {
        id: 174,
        name: "Notification Settings View",
      },
      {
        id: 175,
        name: "Notification Settings Create",
      },
      {
        id: 176,
        name: "Notification Settings Edit",
      },
      {
        id: 177,
        name: "Notification Settings Delete",
      },
      {
        id: 178,
        name: "Reference Number View",
      },
      {
        id: 179,
        name: "Reference Number Create",
      },
      {
        id: 180,
        name: "Reference Number Edit",
      },
      {
        id: 181,
        name: "Reference Number Delete",
      },
    ],
  },
];
