import { useEffect, useState } from 'react'
import axiosInstance from '../../Services/axiosCommon';

function UseFetchCurrency(url,onlyValue=false){
    const [value,setValue] = useState([])
    function getData(){
        axiosInstance.get(url).then(res => {
            let data = res.data;
            let tempData = [];
            data.forEach((item) => {
                if(onlyValue){
                    let tempObject = { value: item.name, label: item.name };
                    tempData.push(tempObject);
                }else{
                    let tempObject = { value: item.id, label: item.name };
                    tempData.push(tempObject);
                }
            });
            res.data && setValue(tempData);
        }).catch(err => {
            console.log(err, "err");
        })
    }
useEffect(()=>{
    getData()
},[])

return {value, getData}||[]
}

export default UseFetchCurrency