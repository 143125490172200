import React, { useEffect, useState } from 'react'
import { BackButton, CreateButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link,useLocation ,useParams} from 'react-router-dom';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import axiosInstance from '../../../../Services/axiosCommon';
import EditIcon from '../../../../Components/Icons/Edit';
import DeleteIcon from '../../../../Components/Icons/Delete';
import NuDate from '../../../../Components/Utils/NuDate';
import capitalizeFirstLetter from '../../../../Components/Utils/Funcs';

function ViewEquipments() {
    const { equipmentViewId} = useParams();
    const location = useLocation();
    const EQdata = location.state.data;
    const pageIndex = location.state?.pageIndex;
    const searchQuery = location.state?.searchQuery;
    
  console.log("bnmv",location.state);
  const handleDelete = (equipmentId) =>{
      axiosInstance.delete(`/equipments/maintenance/${equipmentId}`)
      getEquipmentView()
    }
    const [data, setData] = useState(EQdata?EQdata:null);
    async function getEquipmentMainView() {
      await axiosInstance
        .get(`/equipments/${equipmentViewId}`)
        .then(async (res) => {
          console.log("find", res?.data?.data);
          (await res?.data?.data) && setData(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    const [dataa, setDataa] = useState([]);
    async function getEquipmentView() {
      await axiosInstance
        .get(`/equipments/all-maintenance/${equipmentViewId}`)
        .then(async (res) => {
          // console.log("find", res?.data?.data);
          (await res?.data?.data?.data) && setDataa(res?.data?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    console.log("ccc",dataa);
    
    useEffect(()=>{
      getEquipmentView();
      getEquipmentMainView();
    },[]);
   
return (
  <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
   
       <Breadcrumb
          items={[
            {
              name: "Equipments",
              link: "/maintenance/equipment",
            },
            {
              name: "View Equipment",
            },
          ]}
        />
        
      <div className=" flex gap-2">
        <Link to={'/maintenance/equipment'} state={{currentPage:pageIndex,searchQuery:searchQuery!=""?searchQuery:""}}><BackButton link={true}/></Link>
        <Link to={`/maintenance/equipment/edit/${equipmentViewId}`} 
        state={{data: data, from:`/maintenance/equipment/view/${equipmentViewId}`}}
        ><EditButton height='8'/></Link>
      </div>
    </div> 
    <div className='w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-y-scroll p-3 mt-4'>
    {/* <h2 className='text-base font-normal text-[#111537] p-3'>Customers</h2> */}
  
    <div className='flex justify-between '>
    <h2 className='text-sm font-bold text-[#111537] p-3'>Equipment Detail</h2>
    <div className='mt-3'> <Link to={`/maintenance/equipment/add/${equipmentViewId}`} 
        state={{data: data, from:`/maintenance/equipment/view/${equipmentViewId}`}}
        ><CreateButton label='Maintenance' /></Link></div>
              {/* <div className='mt-3'><Link to={`/maintenance/equipment/add/${equipmentViewId}`}><CreateButton label='Equipments Maintenance' /></Link></div> */}
        </div>
        <div className=" w-full flex flex-wrap ">
                <div className=" w-full grid grid-cols-3 gap-x-8 m-4">
                <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Manufacturer</p>
                <p className='w-1/2 py-2  text-xs   text-[#606279]'>: {data?.manufacturer? capitalizeFirstLetter(data?.manufacturer) : 'N/A'}</p>
              </div>
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Equipment Type</p>
                <p className='w-1/2 py-2  text-xs   text-[#606279]'>: {data?.equipmentType? capitalizeFirstLetter(data.equipmentType) : 'N/A'}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Brand</p>
                <p className='w-1/2 py-2  text-xs   text-[#606279]'>: {data?.brand? capitalizeFirstLetter(data.brand) : 'N/A'}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Unit (kg)</p>
                <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.unit}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Quantity</p>
                <p className='w-1/2 py-2  text-xs   text-[#606279]'>: {data?.quantity}</p>
              </div>
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Warrenty Period</p>
                <p className='w-1/2 py-2  text-xs   text-[#606279]'>: {data?.warrentyPeriod}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Warrenty Expiration Date</p>
                <p className='w-1/2 py-2  text-xs   text-[#606279]'>: {data?.warrentyExpDate ? (
                  <NuDate value={data?.warrentyExpDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
              </div>
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Calibration Request</p>
                <p className='w-1/2 py-2  text-xs   text-[#606279]'>: {data?.calibReq ? 'Yes':'No'}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Calibration Expiration Date</p>
                <p className='w-1/2 py-2  text-xs   text-[#606279]'>: {data?.calibExpDate? (
                  <NuDate value={data?.calibExpDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Description</p>
                <p className='w-1/2 py-2  text-xs   text-[#606279]'>: {data?.description? capitalizeFirstLetter(data.description) : 'N/A'}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Comments</p>
                <p className='w-1/2 py-2  text-xs text-[#606279]'>: {data?.comments? capitalizeFirstLetter(data.comments) : 'N/A'}</p>
              </div>
             </div>
        <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>

             <div className="w-full h-48 bg-white overflow-y-scroll mt-1">
            <div className="w-full py-[1%] bg-baseColor-primary flex justify-between rounded-lg px-2">
              <p className=" w-[15%] text-left pl-2 text-white text-xs font-medium"> Maintenance Date</p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">Maintenance By</p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">Maintenance Type</p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">Next Maintenance</p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">Comments</p>
              {/* <p className=" w-[10%] text-center text-white text-xs font-medium"> Edit</p> */}
              <p className=" w-[5%] text-center text-white text-xs font-medium">  Action </p>
            </div>
            {dataa?.map((List, index) => {
              return (
                <div key={index} className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100 px-2">
                  <p className=" w-[15%] text-left pl-2 text-black text-xs font-light ">
                    {List?.mainDate? (
                  <NuDate value={List?.mainDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}
                  </p>
                  <p className=" w-[20%] text-center text-black text-xs font-light ">
                    {List?.mainBy? capitalizeFirstLetter(List?.mainBy) : 'None'}
                  </p>
                  <p className=" w-[20%] text-center text-black text-xs font-light ">
                    {List?.mainType? capitalizeFirstLetter(List?.mainType) : 'None'}
                  </p>
                  <p className=" w-[20%] text-center text-black text-xs font-light ">
                    {List?.nextMain? (
                  <NuDate value={List?.nextMain} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}
                  </p>
                  <p className=" w-[20%] text-center text-black text-xs font-light ">
                    {List?.comments? capitalizeFirstLetter(List?.comments) : 'None'}
                  </p>
                  <div className=' w-[5%] text-center'>
                  <button
                    type="button"
                    className="text-yellow-500 cursor-pointer bg-white  border-none"
                  >
                  <Link to={`/maintenance/equipment/edit-equipment/${List?.maintanenceId}`} state={{data:List, dataView:data, from:`/maintenance/equipment/view/${equipmentViewId}`}}>
                    <EditIcon/>
                  </Link>
                  </button>                
                 
                  <button
                    onClick={() => {
                    //  handledelete(List?.id,List?.paidAmount,List?.balanceAmount,setIsModalOpen(true))
                    handleDelete(List?.maintanenceId)
                  }}
                    type="button"
                    className="text-yellow-500 cursor-pointer bg-white ml-1 border-none"
                  >
                     <DeleteIcon/>
                  </button>
                  </div>
                </div>
              );
            })}
          </div>
                  </div>
          </div>
          
      </div>
  
  )
};
export default ViewEquipments;