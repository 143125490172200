import React, { useCallback, useState } from "react";
import Select from "react-select";
import { CountryMIN } from "../../assets/CountryMini";
import UsePeoductDropDown from "../Hooks/useProductSearch";

function FormikSelectTwo({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options,
  disabled = false,
  customClass,
  px = "6",
  isRequired,
  width,
  FixPosition=true,
  PortalTarget= false,
  zindex=1,
  isClear=false
}) {
  const options = Options;

  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e?.value||"",
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      fontWeight: "500",
      width: "100%",
      // zIndex: 1,
    }),
    menuPortal: (base) => ({
      ...base,
       zIndex: zindex, // Keep dropdown inside container boundaries
      // position: 'absolute', // Prevent it from rendering at the top
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "8px 12px",  // Default padding
      fontSize: "12px",  // Default font size for small screens
      [`@media (min-width: 640px)`]: {
        fontSize: "12px",  // sm breakpoint (Tailwind sm: text-sm)
      },
      [`@media (min-width: 768px)`]: {
        fontSize: "12px",  // md breakpoint (Tailwind md: text-base)
      },
      [`@media (min-width: 1024px)`]: {
        fontSize: "12px",  // lg breakpoint (Tailwind lg: text-lg)
      },
      [`@media (min-width: 1536px)`]: {
        fontSize: "14px",  // lg breakpoint (Tailwind lg: text-lg)
      },
      color: state.isSelected ? '#fff' : '#000',  // Custom colors for selected options
      backgroundColor: state.isSelected ? '#2563EB' : '#fff',  // Custom background color for selected option
      ':hover': {
        color:'#fff',
        backgroundColor: '#60A5FA',  // Hover state color
      },
    }),
  };

  return (
    <div className={`${width} relative px-${px} py-2`}>
      <label className={`${labelclassname} text-xs 2xl:text-sm`}>
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <div className="mt-1">
        <Select
          isDisabled={disabled}
          options={options}
          value={options.find((i) => i.value === formik.values[name]) || ""}
          styles={styles}
          className={`${classname} text-xs 2xl:text-sm mt-2 font-medium ${disabled ? "bg-slate-100 cursor-not-allowed" : ""}`}
          name={name}
          onChange={onChange || handleChange}
          placeholder={placeholder}
          menuPlacement="auto"
          menuPosition={FixPosition==true?"fixed":"absolute"}
          menuPortalTarget={PortalTarget==true?document.body:""} // Render the dropdown in the body
          isClearable={isClear}


        />
      </div>
      {formik.errors[name] ? (
        <span className="text-xs font-semibold text-red-500">
          {formik.errors[name]}
        </span>
      ) : null}
    </div>
  );
}

export default FormikSelectTwo;


export  function TwoNEW({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options,
  disabled = false,
  customClass,
  px = "6",
  isRequired,
  width,
  setSearch,
  debounceTime = 900,

  FixPosition=true,
  PortalTarget= false,

  zindex=1
}) {
  const options = Options;

  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e.value,
        name: name,
      },
    });
  };
  const [loading, setLoading] = useState(false);

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // const handleInputChange = useCallback(
  //   debounce(async (inputValue) => {
  //     if (inputValue?.length >= 1  ) {
  //       setLoading(true);
  //       setSearch(inputValue);
  //       setLoading(false);
  //     }
  //   }, debounceTime),
  //   []
  // );

  const handleInputChange = useCallback(
    debounce(async (inputValue) => {
      const trimmedValue = inputValue.trim();
      if (trimmedValue.length >= 1) { 
        setLoading(true);
        setSearch(trimmedValue);
        setLoading(false);
      }else if(inputValue.length==0){
        setLoading(true);
        setSearch(inputValue);
        setLoading(false);

      }
    }, debounceTime),
    [debounceTime]
  );

  const styles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      fontWeight: "500",
      width: "100%",
      // zIndex: 1,
    }),
    menuPortal: (base) => ({
      ...base,
       zIndex: zindex, // Keep dropdown inside container boundaries
      // position: 'absolute', // Prevent it from rendering at the top
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "8px 12px",  // Default padding
      fontSize: "12px",  // Default font size for small screens
      [`@media (min-width: 640px)`]: {
        fontSize: "12px",  // sm breakpoint (Tailwind sm: text-sm)
      },
      [`@media (min-width: 768px)`]: {
        fontSize: "12px",  // md breakpoint (Tailwind md: text-base)
      },
      [`@media (min-width: 1024px)`]: {
        fontSize: "12px",  // lg breakpoint (Tailwind lg: text-lg)
      },
      [`@media (min-width: 1536px)`]: {
        fontSize: "14px",  // lg breakpoint (Tailwind lg: text-lg)
      },
      color: state.isSelected ? '#fff' : '#000',  // Custom colors for selected options
      backgroundColor: state.isSelected ? '#2563EB' : '#fff',  // Custom background color for selected option
      ':hover': {
        color:'#fff',
        backgroundColor: '#60A5FA',  // Hover state color
      },
    }),
  };

  return (
    <div className={`${width} relative px-${px} py-2`}>
      <label className={`${labelclassname} text-xs 2xl:text-sm`}>
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <div className="mt-1">
        <Select
          isDisabled={disabled}
          options={options}
          value={options.find((i) => i.value === formik.values[name]) || ""}
          styles={styles}
          className={`${classname} text-xs 2xl:text-sm mt-2 font-medium ${disabled ? "bg-slate-100 cursor-not-allowed" : ""}`}
          name={name}
          onChange={onChange || handleChange}
          placeholder={placeholder}
          menuPlacement="auto"
          menuPosition={FixPosition==true?"fixed":"absolute"}
          menuPortalTarget={PortalTarget==true?document.body:""} // Render the dropdown in the body
          onInputChange={handleInputChange}
          isLoading={loading}
        
        />
      </div>
      {formik.errors[name] ? (
        <span className="text-xs font-semibold text-red-500">
          {formik.errors[name]}
        </span>
      ) : null}
    </div>
  );
}





export function SelectCountry({
  classname,
  labelclassname,
  name,
  onChange,
  formik,
  isRequired,
  width,
  FixPosition=true,
  PortalTarget= false
}) {


  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e.value,
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      // fontSize:'13px',
      fontWeight: "500",
      width: "100%",
      // zIndex:0
    }),
  };
  return (
    <>
      <div className={`${width} relative px-6 py-2`}>
        <label className={`${labelclassname} text-xs 2xl:text-sm`}>
          Country
          {isRequired ? <span className="text-red-500"> *</span> : ""}
        </label>
        <div className="mt-2">
          <Select
            options={CountryMIN}
            value={CountryMIN.find((i) => i.value === formik.values[name]) || ""}
            styles={styles}
            className={`${classname} text-xs capitalize 2xl:text-sm mt-2 font-medium `}
            name={name}
            onChange={onChange || handleChange}
            placeholder={"Choose Country"}
            menuPlacement="auto"
            menuPosition={FixPosition==true?"fixed":"absolute"}
            menuPortalTarget={PortalTarget==true?document.body:""} // Render the dropdown in the body
  
          />
        </div>
        {formik.errors[name] ? (
          <span className="text-xs font-semibold text-red-500 ">
            {formik.errors[name]}
          </span>
        ) : null}
      </div>
    </>
  );
}

export function SelectWithError({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options,
  disabled=false,
  customClass,
  px="6",
  isRequired,
  width,
  ErrMessage,
  handleClick=()=>{},
  FixPosition=true,
  PortalTarget= false
}) {
  const options = Options;

  const handleChange = (e) => {
    console.log("sssss", e);
    
    formik.handleChange({
      target: {
        value: e.value,
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      // fontSize:'13px',
      fontWeight: "500",
      width: "100%",
      // zIndex:0
    }),
  };
  return (
    <>
      <div className={`${width} relative px-${px} py-2`}>
      <div className=" w-full flex gap-2">
        <label className={`${labelclassname} text-xs 2xl:text-sm w-[90%]`}>
          {label}
          {isRequired ? <span className="text-red-500"> *</span> : ""}
        </label>
        <p className="pl-2 text-xs font-semibold text-red-500 ">{ErrMessage}</p>

        <button onClick={handleClick} type="button" className="w-6  text-center h-6 cursor-pointer ml-5 bg-[#048178] flex justify-center items-center border border-solid border-white  text-white font-semibold rounded-lg " > + </button>

        </div>
        {/* <div className="flex">
        <label className={`${labelclassname} text-xs 2xl:text-sm`}>
          {label}
          {isRequired ? <span className="text-red-500"> *</span> : ""}
        </label>
        <p className="pl-2 text-xs font-semibold text-red-500 ">{ErrMessage}</p>
        </div> */}
        <div className="mt-2">
          <Select 
            isDisabled={disabled ? true : false}
            options={options}
            value={options.find((i) => i.value === formik.values[name]) || ""}
            styles={styles}
            className={`${classname} text-xs capitalize 2xl:text-sm mt-2 font-medium ${disabled === true? "bg-slate-100 cursor-not-allowed":""}`}
            name={name}
            onChange={onChange || handleChange}
            placeholder={placeholder}
            menuPlacement="auto"
            menuPosition={FixPosition==true?"fixed":"absolute"}
            menuPortalTarget={PortalTarget==true?document.body:""} // Render the dropdown in the body
          />
        </div>
        {formik.errors[name] ? (
          <span className="text-xs font-semibold text-red-500 ">
            {formik.errors[name]}
          </span>
        ) : null}
      </div>
    </>
  );
}



// export function FormikSelectWithAdd({
//   classname,
//   labelclassname,
//   name,
//   placeholder,
//   onChange,
//   formik,
//   label,
//   Options,
//   disabled = false,
//   customClass,
//   px = "6",
//   isRequired,
//   width,
//   handleClick=()=>{},
//   debounceTime = 900,
//   setSearch
// }) {

//   const options = Options;

//   const [loading, setLoading] = useState(false);

//   // Debounce function to limit API calls
//   const debounce = (func, delay) => {
//     let timer;
//     return (...args) => {
//       clearTimeout(timer);
//       timer = setTimeout(() => func(...args), delay);
//     };
//   };

//   // Handle dynamic search
//   const handleInputChange = useCallback(
//     debounce(async (inputValue) => {
//       if(inputValue) {
//         if (inputValue.length>=3) {
//           setLoading(true);
//           // const data = UsePeoductDropDown('/items/new-drop-down',inputValue)||[]; // Call API
//           // setOptions(data);
//           setSearch(inputValue);
//           setLoading(false);
//         }
//       }
//     }, debounceTime),
//     []
//   );

//   const handleChange = (e) => {
//     formik.handleChange({
//       target: {
//         value: e.value,
//         name: name,
//       },
//     });
//   };

//   const styles = {
//     control: (provided) => ({
//       ...provided,
//       border: "1px solid #e5e5e5",
//       outline: "none",
//       borderRadius: "10px",
//       padding: "1px 0",
//       color: "#000",
//       textAlign: "left",
//       fontWeight: "500",
//       width: "100%",
//       // zIndex: 0,
//     }),
//     menuPortal: (base) => ({
//       ...base,
//       zIndex: 1,  // Ensure it's above other elements
//       position: 'absolute',
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       padding: "8px 12px",  // Default padding
//       fontSize: "12px",  // Default font size for small screens
//       [`@media (min-width: 640px)`]: {
//         fontSize: "12px",  // sm breakpoint (Tailwind sm: text-sm)
//       },
//       [`@media (min-width: 768px)`]: {
//         fontSize: "12px",  // md breakpoint (Tailwind md: text-base)
//       },
//       [`@media (min-width: 1024px)`]: {
//         fontSize: "12px",  // lg breakpoint (Tailwind lg: text-lg)
//       },
//       [`@media (min-width: 1536px)`]: {
//         fontSize: "14px",  // lg breakpoint (Tailwind lg: text-lg)
//       },
//       color: state.isSelected ? '#fff' : '#000',  // Custom colors for selected options
//       backgroundColor: state.isSelected ? '#2563EB' : '#fff',  // Custom background color for selected option
//       ':hover': {
//         color:'#fff',
//         backgroundColor: '#60A5FA',  // Hover state color
//       },
//     }),
//   };

//   return (
//     <div className={`${width} relative px-${px} py-2`}>
//         <div className=" w-full flex gap-2">
//         <label className={`${labelclassname} text-xs 2xl:text-sm w-[90%]`}>
//           {label}
//           {isRequired ? <span className="text-red-500"> *</span> : ""}
//         </label>
//         <button onClick={handleClick} type="button" className="w-6  text-center h-6 cursor-pointer bg-[#048178] flex justify-center items-center border border-solid border-white  text-white font-semibold rounded-lg " > + </button>

//         </div>
//       <div className="mt-2">
//         <Select
//           isDisabled={disabled}
//           options={options}
//           value={options.find((i) => i.value === formik.values[name]) || ""}
//           styles={styles}
//           menuPortalTarget={document.body} // Render the dropdown in the body
//           className={`${classname} text-xs 2xl:text-sm mt-2 font-medium ${disabled ? "bg-slate-100 cursor-not-allowed" : ""}`}
//           name={name}
//           onChange={onChange || handleChange}
//           placeholder={placeholder}
//           menuPlacement="auto"
//           menuPosition="absolute"
//           onInputChange={handleInputChange} // Handle input change
//           isLoading={loading}
//         />
//       </div>
//       {formik.errors[name] ? (
//         <span className="text-xs font-semibold text-red-500">
//           {formik.errors[name]}
//         </span>
//       ) : null}
//     </div>
//   );
// }


export function FormikSelectWithAdd({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options,
  disabled = false,
  customClass,
  px = "6",
  isRequired,
  width,
  handleClick = () => {},
  debounceTime = 900,
  setSearch,
  FixPosition=true,
  PortalTarget= false
}) {
  const options = Options;
  const [loading, setLoading] = useState(false);

  // Debounce function to limit API calls
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Handle dynamic search
  const handleInputChange = useCallback(
    debounce(async (inputValue) => {
      if (inputValue?.length >= 2) {
        setLoading(true);
        setSearch(inputValue);
        setLoading(false);
      }
    }, debounceTime),
    []
  );

  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e.value,
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #e5e5e5",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      fontWeight: "500",
      width: "100%",
    }),
    menu: (provided) => ({
      ...provided,
      // zIndex: 1, // Keep dropdown inside container boundaries
      // position: 'absolute', // Prevent it from rendering at the top
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "8px 12px",
      fontSize: "12px",
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#2563EB" : "#fff",
      ":hover": {
        backgroundColor: "#60A5FA",
        color: "#fff",
      },
    }),
  };

  return (
    <div className={`${width} relative px-6 py-2`}>
      <div className="w-full flex gap-2">
        <label className={`${labelclassname} text-xs 2xl:text-sm w-[90%]`}>
          {label}
          {isRequired && <span className="text-red-500"> *</span>}
        </label>
        <button
          onClick={handleClick}
          type="button"
          className="w-6 h-6 bg-[#048178] text-white font-semibold rounded-lg flex items-center justify-center border border-white"
        >
          +
        </button>
      </div>
      <div className="mt-2">
        <Select
          isDisabled={disabled}
          options={options}
          value={options.find((i) => i.value === formik.values[name]) || ""}
          styles={styles}
          className={`${classname} text-xs 2xl:text-sm mt-2 font-medium ${
            disabled ? "bg-slate-100 cursor-not-allowed" : ""
          }`}
          name={name}
          onChange={onChange || handleChange}
          placeholder={placeholder}
          menuPlacement="auto" // Adjusts dropdown placement within available space
          menuPosition={FixPosition==true?"fixed":"absolute"}
          menuPortalTarget={PortalTarget==true?document.body:""}
          onInputChange={handleInputChange}
          isLoading={loading}
        />
      </div>
      {formik.errors[name] && (
        <span className="text-xs font-semibold text-red-500">
          {formik.errors[name]}
        </span>
      )}
    </div>
  );
}


export function FormikSelectWithoutAdd({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options,
  disabled = false,
  customClass,
  px = "6",
  isRequired,
  width,
  handleClick=()=>{},
  debounceTime = 900,
  setSearch,
  FixPosition=true,
  PortalTarget= false
}) {

  const options = Options;

  const [loading, setLoading] = useState(false);

  // Debounce function to limit API calls
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Handle dynamic search
  const handleInputChange = useCallback(
    debounce(async (inputValue) => {
      if(inputValue) {
        if (inputValue.length>=3) {
          setLoading(true);
          // const data = UsePeoductDropDown('/items/new-drop-down',inputValue)||[]; // Call API
          // setOptions(data);
          setSearch(inputValue);
          setLoading(false);
        }
      }
    }, debounceTime),
    []
  );

  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e.value,
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      fontWeight: "500",
      width: "100%",
      zIndex: 0,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 1,  // Ensure it's above other elements
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "8px 12px",  // Default padding
      fontSize: "12px",  // Default font size for small screens
      [`@media (min-width: 640px)`]: {
        fontSize: "12px",  // sm breakpoint (Tailwind sm: text-sm)
      },
      [`@media (min-width: 768px)`]: {
        fontSize: "12px",  // md breakpoint (Tailwind md: text-base)
      },
      [`@media (min-width: 1024px)`]: {
        fontSize: "12px",  // lg breakpoint (Tailwind lg: text-lg)
      },
      [`@media (min-width: 1536px)`]: {
        fontSize: "14px",  // lg breakpoint (Tailwind lg: text-lg)
      },
      color: state.isSelected ? '#fff' : '#000',  // Custom colors for selected options
      backgroundColor: state.isSelected ? '#2563EB' : '#fff',  // Custom background color for selected option
      ':hover': {
        color:'#fff',
        backgroundColor: '#60A5FA',  // Hover state color
      },
    }),
  };

  return (
    <div className={`${width} relative px-${px} py-2`}>
        {/* <div className=" w-full flex gap-2"> */}
        <label className={`${labelclassname} text-xs 2xl:text-sm w-[90%]`}>
          {label}
          {isRequired ? <span className="text-red-500"> *</span> : ""}
        </label>
        {/* <button onClick={handleClick} type="button" className="w-6  text-center h-6 cursor-pointer bg-[#048178] flex justify-center items-center border border-solid border-white  text-white font-semibold rounded-lg " > + </button> */}

        {/* </div> */}
      <div className="mt-2">
        <Select
          isDisabled={disabled}
          options={options}
          value={options.find((i) => i.value === formik.values[name]) || ""}
          styles={styles}
          // menuPortalTarget={document.body} // Render the dropdown in the body
          className={`${classname} text-xs 2xl:text-sm mt-2 font-medium ${disabled ? "bg-slate-100 cursor-not-allowed" : ""}`}
          name={name}
          onChange={onChange || handleChange}
          placeholder={placeholder}
          menuPlacement="auto"
          menuPosition={FixPosition==true?"fixed":"absolute"}
          menuPortalTarget={PortalTarget==true?document.body:""}
          onInputChange={handleInputChange} // Handle input change
          isLoading={loading}
        />
      </div>
      {formik.errors[name] ? (
        <span className="text-xs font-semibold text-red-500">
          {formik.errors[name]}
        </span>
      ) : null}
    </div>
  );
}



export function SelectProductWithError({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options = [], // Default empty array
  disabled = false,
  customClass,
  px = "6",
  isRequired,
  width,
  ErrMessage,
  hasAddButton=true,
  handleClick = () => {},
  fetchOptions=()=>{}, // New prop to fetch options via API
  debounceTime = 900, // Optional prop for debounce time
  setSearch,
  FixPosition=true,
  PortalTarget= false
}) {
  // const [options, setOptions] = useState(Options);
  let options = Options || [];
  const [loading, setLoading] = useState(false);

  // Debounce function to limit API calls
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Handle dynamic search
  const handleInputChange = useCallback(
    debounce(async (inputValue) => {
      if(inputValue) {
        if (inputValue.length>=3) {
          setLoading(true);
          // const data = UsePeoductDropDown('/items/new-drop-down',inputValue)||[]; // Call API
          // setOptions(data);
          setSearch(inputValue);
          setLoading(false);
        }
      }
      else if(inputValue.length==0){
        setLoading(true);
        setSearch(inputValue);
        setLoading(false);

      }
    }, debounceTime),
    []
  );

  const handleChange = (selectedOption) => {
    formik.handleChange({
      target: {
        value: selectedOption ? selectedOption.value : "",
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      fontWeight: "500",
      width: "100%",
    }),
  };

  return (
    <div className={`${width} relative px-${px} py-2`}>
      <div className="w-full flex gap-2">
        <label className={`${labelclassname} text-xs 2xl:text-sm w-[90%]`}>
          {label}
          {isRequired && <span className="text-red-500"> *</span>}
        </label>
        <p className="pl-2 text-xs font-semibold text-red-500">
          {ErrMessage}
        </p>
        {hasAddButton &&
          <button
          onClick={handleClick}
          type="button"
          className="w-6 text-center h-6 cursor-pointer ml-5 bg-[#048178] flex justify-center items-center border border-solid border-white text-white font-semibold rounded-lg"
        >
          +
        </button>}
      </div>
      <div className="mt-2">
        <Select
          isDisabled={disabled}
          options={options}
          value={options.find((i) => i.value === formik.values[name]) || ""}
          styles={styles}
          className={`${classname} text-xs capitalize 2xl:text-sm mt-2 font-medium ${
            disabled ? "bg-slate-100 cursor-not-allowed" : ""
          }`}
          name={name}
          onChange={onChange || handleChange}
          placeholder={placeholder}
          menuPlacement="auto"
          menuPosition={FixPosition==true?"fixed":"absolute"}
          menuPortalTarget={PortalTarget==true?document.body:""}
          onInputChange={handleInputChange} // Handle input change
          isLoading={loading} // Show loader
        />
      </div>
      {formik.errors[name] && (
        <span className="text-xs font-semibold text-red-500">
          {formik.errors[name]}
        </span>
      )}
    </div>
  );
}
